import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { oauth } from '../utils';

const initialState = {
  data: {
    permissions: {},
    roles: {},
    role_permissions: {},
  },
  loading: {},
  errors: {},
};

const slice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions(state, action) {
      state.data = action.payload;
    },
    fetchRolePermissionsSuccess(state, action) {
      state.data.permissions = action.payload.permissions.reduce((acc, v) => ({
        ...acc,
        [v.permission_id]: v,
      }), {});
      state.data.roles = action.payload.roles.reduce((acc, v) => ({
        ...acc,
        [v.role_id]: v,
      }), {});
      state.data.role_permissions = action.payload.role_permissions;
    },
    createRolePermissionSuccess(state, action) {
      state.data.role_permissions[action.payload.role_id] = {
        ...state.data.role_permissions[action.payload.role_id],
        [action.payload.permission_id]: action.payload.mandatory,
      };
      return state;
    },
    removeRolePermissionSuccess(state, action) {
      if (_.has(state.data.role_permissions, action.payload.role_id)) {
        delete state.data.role_permissions[action.payload.role_id][action.payload.permission_id];
      }
      return state;
    },
    updateRoleSuccess(state, action) {
      state.data.roles[action.payload.role_id] = {
        ...(state.data.roles[action.payload.role_id] || {}),
        ...action.payload,
      };
    },
    permissionsFailure(state, action) {
      state.errors = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    updateLoading(state, action) {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
  },
});

const fetchRolePermissions = (params={}) => async (dispatch, getState) => {
  dispatch(updateLoading({permissions: true}));
  try {
    const { json } = await oauth('GET', 'role-permission', params);

    dispatch(fetchRolePermissionsSuccess(json));
    return json;
  } catch (error) {
    console.error('ERROR|fetchRolePermissions| ', error);
    dispatch(permissionsFailure(getErrorMessageFromError(error, 'Cannot get permissions.')));
  }
  dispatch(updateLoading({permissions: false}));
  return null;
};

const createRolePermission = (role_id, permission_id) => async (dispatch, getState) => {
  dispatch(updateLoading({permissions: true}));
  try {
    const { json } = await oauth('POST', 'role-permission/', { role_id, permission_id });
    dispatch(createRolePermissionSuccess(json.role_permission));
    return json;
  } catch (error) {
    console.error('ERROR|createRolePermission| ', error);
    dispatch(permissionsFailure(getErrorMessageFromError(error, 'Cannot add role permission.')));
  }
  dispatch(updateLoading({permissions: false}));
  return null;
};

const removeRolePermission = (role_id, permission_id) => async (dispatch, getState) => {
  dispatch(updateLoading({permissions: true}));
  try {
    const { json } = await oauth('DELETE', 'role-permission/', { role_id, permission_id });
    dispatch(removeRolePermissionSuccess({ role_id, permission_id }));
    return json;
  } catch (error) {
    console.error('ERROR|removeRolePermission| ', error);
    dispatch(permissionsFailure(getErrorMessageFromError(error, 'Cannot remove role permission.')));
  }
  dispatch(updateLoading({permissions: false}));
  return null;
};

const updateRole = (role_id, params={}) => async (dispatch, getState) => {
  dispatch(updateLoading({roles: true}));
  try {
    const { json } = await oauth('PUT', 'role/', { role_id, ...params });
    dispatch(updateRoleSuccess({ role_id, ...params, }));
    return json;
  } catch (error) {
    console.error('ERROR|updateRole| ', error);
    dispatch(permissionsFailure(getErrorMessageFromError(error, 'Cannot update role.')));
  }
  dispatch(updateLoading({roles: false}));
  return null;
};

function getErrorMessageFromError(error, defaultMsg = "Unable to perform this action") {
  if (typeof error === 'string') { return error; }
  if (_.get(error, 'message', false)) {
    return _.get(error, 'message', false);
  }
  if (_.get(error, 'detail', false)) {
    return _.get(error, 'detail', false);
  }
  return defaultMsg;
}

// Exports
export const selectPermissions = state => _.get(state, ['permissions', 'data', 'permissions'], {});
export const selectPermissionList = createSelector(
  selectPermissions,
  s => Object.values(s)
);
export const selectRoles = state => _.get(state, ['permissions', 'data', 'roles'], {});
export const selectRoleList = createSelector(
  selectRoles,
  s => Object.values(s)
);
export const selectRolePermissions = state => _.get(state, ['permissions', 'data', 'role_permissions'], {});
export const selectPermissionsLoading = state => state.permissions.loading;
export const selectPermissionsErrors = state => state.permissions.errors;
export const selectors = {
  permissions: createSelector(selectPermissions),
  loading: createSelector(selectPermissionsLoading),
  errors: createSelector(selectPermissionsErrors),
  byId: createSelector([selectPermissions, (s, permissionId) => permissionId], (s, permissionId) => s[permissionId]),
  options: createSelector(selectPermissions, s => Object.values(s).map(v => ({
    label: v.permission_name,
    value: v.permission_id,
  }))),
};

export const {
  setPermissions,
  fetchRolePermissionsSuccess,
  createRolePermissionSuccess,
  removeRolePermissionSuccess,
  permissionsFailure,
  setLoading,
  updateLoading,
  updateRoleSuccess,
} = slice.actions;

export {
  fetchRolePermissions,
  createRolePermission,
  removeRolePermission,
  updateRole,
};

export default slice.reducer;
