import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, LabeledInput, Toggle, ToggleLink, TabBar, Tab, AlertNotification } from '@commonsku/styles';
import { getActiveUsersList, getUsersList } from '../../../selectors/users';
import { addTeamUser, selectors, updateTeam } from '../../../redux/teams';
import EditGroupUserRow from './EditGroupUserRow';
import AddGroupUserRow from '../add-group/AddGroupUserRow';
import { getFeatureIdByName, getSocialFeatureId } from '../../../selectors';

const TAB_TYPE = {
  MANAGE_MEMBERS: 'MANAGE_MEMBERS',
  ADD_MEMBERS: 'ADD_MEMBERS',
};
const EditGroupForm = React.memo((props) => {
  const {
    teamId,
  } = props;

  const dispatch = useDispatch();
  const group = useSelector(s => selectors.byId(s, teamId));
  const usersNotInGroup = useSelector(s =>  {
    const gr = selectors.byId(s, teamId) || { team_users: [] };
    const teamUserIds = gr.team_users.map(v => v.user_id);
    return getActiveUsersList(s)
      .filter(v => ![
        getSocialFeatureId(s),
        getFeatureIdByName(s, 'DORMANT')
      ].includes(v.feature_id) && !teamUserIds.includes(v.user_id));
  });
  const groupUsersList = useSelector(s => {
    const gr = selectors.byId(s, teamId) || { team_users: [] };
    const teamUserIds = gr.team_users.map(v => v.user_id);
    return getUsersList(s)
      .filter(v => teamUserIds.includes(v.user_id));
  });
  const [selectedTab, setSelectedTab] = useState(TAB_TYPE.MANAGE_MEMBERS);
  const [state, setState] = useState(group);
  const [showNameError, setShowNameError] = useState(false);

  const isActive = (''+ _.get(state, ['active'])) === '1';
  const onChangeGroup = (data={}) => {
    dispatch(updateTeam(teamId, data));
  };

  const updateGroupName = useCallback(
    _.debounce((value) => {
      dispatch(updateTeam(teamId, { team_name: value }));
    }, 600),
    [dispatch, teamId]
  );

  useEffect(() => {
    setState(group);
  }, [group]);

  if (!teamId || !group) {
    return null;
  }

  return (
    <Row className="edit-group-row">
      <Col xs>
        <GroupNameInput
          value={state.team_name}
          onChange={val => {
            if (!val) { return; }
            setState(s => ({ ...s, team_name: val }));
            updateGroupName(val);
            dispatch(updateTeam(teamId, { team_name: val }));
          }}
          setShowError={setShowNameError}
        />
        <div style={{ display: 'inline', paddingLeft: 10, width: 200, }}>
          <Toggle className="edit-group-toggle">
            <ToggleLink
              className="edit-group-toggle-active"
              onClick={() => onChangeGroup({ active: 1 })}
              selected={isActive}
            >Active</ToggleLink>
            <ToggleLink
              className="edit-group-toggle-inactive"
              onClick={() => onChangeGroup({ active: 0 })}
              selected={!isActive}
            >Inactive</ToggleLink>
          </Toggle>
        </div>
      </Col>
      {showNameError && <Col xs>
        <AlertNotification alertType='error' style={{ display: 'block' }}>
          Group name must not be empty
        </AlertNotification>
      </Col>}

      <Col xs>
        <TabBar padded className="edit-group-members-tabs">
          <Tab
            className="edit-group-manage-members-tab"
            variant='secondary'
            style={{ paddingBottom: 8, }}
            onClick={() => setSelectedTab(TAB_TYPE.MANAGE_MEMBERS)}
            selected={selectedTab === TAB_TYPE.MANAGE_MEMBERS}
          >Manage Group Members</Tab>
          <Tab
            className="edit-group-add-members-tab"
            variant='secondary'
            style={{ paddingBottom: 8, }}
            onClick={() => setSelectedTab(TAB_TYPE.ADD_MEMBERS)}
            selected={selectedTab === TAB_TYPE.ADD_MEMBERS}
          >Add Group Members</Tab>
        </TabBar>
      </Col>

      {selectedTab === TAB_TYPE.MANAGE_MEMBERS && <Col xs>
        {groupUsersList.map((u, i) => (
          <EditGroupUserRow
            key={`team-user-edit-${i}`}
            teamId={teamId}
            user={u}
            canDelete={groupUsersList.length > 1}
          />
        ))}
      </Col>}

      {selectedTab === TAB_TYPE.ADD_MEMBERS && <Col xs>
        {usersNotInGroup.filter(v => v.user_id).map((u, i) => {
          const foundIdx = group.team_users.findIndex(v => v === u.user_id);
          return (
            <AddGroupUserRow
              key={`team-user-edit-${i}`}
              teamId={teamId}
              user={u}
              foundIndex={foundIdx}
              updateGroupUser={() => dispatch(addTeamUser(teamId, u.user_id))}
            />
          );
        })}
      </Col>}
    </Row>
  );
});

const GroupNameInput = (props) => {
  const {
    value,
    onChange,
    setShowError,
  } = props;

  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  const handleChange = useCallback(
    _.debounce((val) => {
      if (!val) {
        return;
      }
      onChange(val);
    }, 700),
    []
  );

  return (<>
    <LabeledInput
      className="edit-group-team_name"
      label='Group name (required)'
      value={state}
      onChange={e => {
        const val = e.target.value;
        setState(val);

        if (!(val || '').trim()) {
          setShowError(true);
        } else {
          setShowError(false);
        }
        handleChange(val.trim());
      }}
      style={{ width: 324 }}
      wrapperProps={{style: {display: 'inline'},}}
      error={!state.trim()}
    />
  </>);
};

export default EditGroupForm;
