import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ArrowIcon, Avatar, Button, CheckmarkIcon, ClipboardIcon, Col, colors, fontFamilies, IconButton, Link, Loading, Popup, PopupHeader, Row, Tab, TabBar, Text } from '@commonsku/styles';
import CreateUserForm from './CreateUserForm';
import ReactivateUsersForm from './ReactivateUsersForm';
import { createAddUser, createLoadUsers, createUpdateUserData } from '../../../actions';
import { useIdentity } from '../../../hooks';
import { parseAddUserRequestData } from '../utils';
import { getFeatureIdByName, getRoleOptions } from '../../../selectors';
import { incrementActiveUsers, isValidQuantity, selectStripeActiveUsers, selectStripeCalculatedQuantity, } from '../../../redux/stripe';
import ReactTooltip from '../../helpers/ReactTooltip';
import { getUsers } from '../../../selectors/users';
import EditUserForm from './EditUserForm';
import { formatActivationLink, getIdentityUtils, oauth, validateEmailExtensive } from '../../../utils';

const FORM_TYPE = {
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  REACTIVATE_USER: 'REACTIVATE_USER',
};

const CreateUserPopup = (props) => {
  const {
    onClose,
  } = props;

  const identity = useIdentity();
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const roleOptions = useSelector(getRoleOptions);
  const companyFeatureId = useSelector(s => getFeatureIdByName(s, identity.features));
  const minQuantity = useSelector(selectStripeActiveUsers);
  const quantity = useSelector(selectStripeCalculatedQuantity);
  const invalidQuantity = !isValidQuantity(quantity);

  const { isTenant, isTeam, isSupplier, } = getIdentityUtils(identity);

  const [showErrors, setShowErrors] = useState(false);
  const [state, setState] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email_username: '',
    contact_email_domain: '',
    contact_email: '',
    login_name: '',
    contact_position: '',
    role_id: '',
    production_contact: '0',
    invoicing_contact: '0',
    address: {address_line_1: "", address_city: "", address_state: "", address_country: "", address_postal: ""},
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formType, setFormType] = useState(FORM_TYPE.ADD_USER);
  const [reactivateUserId, setReactivateUserId] = useState('');
  const [userCreated, setUserCreated] = useState(false);
  const [userReactivated, setUserReactivated] = useState(false);
  const [userActivationLink, setUserActivationLink] = useState(undefined);

  const [editUserForm, setEditUserForm] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    login_name: '',
    contact_position: '',
    role_id: '',
    production_contact: '0',
    invoicing_contact: '0',
    address: {address_line_1: "", address_city: "", address_state: "", address_country: "", address_postal: ""},
  });

  useEffect(() => {
    if (!reactivateUserId) { return; }
    const reactivateUser = _.get(users, [reactivateUserId]);
    if (!reactivateUser) { return; }
    setEditUserForm(reactivateUser);
  }, [users, reactivateUserId]);

  const updateField = useCallback((field, value) => {
    setState(s => ({ ...s, [field]: value }));
  }, []);

  const updateEditUserField = useCallback((field, value) => {
    setEditUserForm(s => ({ ...s, [field]: value }));
  }, []);

  const addError = useCallback((err) => {
    if (!err) { return; }
    setErrors(s => {
      const result = s;
      if (!s.includes([err])) {
        result.push(err);
      }
      return result;
    });
  }, []);

  const contactEmail = (state.contact_email || `${state.contact_email_username || ''}@${state.contact_email_domain || ''}`).trim();
  const checkUserInfo = () => {
    if (!contactEmail) {
      addError('invalid_email');
      return false;
    }
    if (!state.role_id && isTenant()) {
      setErrors(s => s.concat(['invalid_role']));
      addError('invalid_role');
      return false;
    }
    if (!(state.contact_first_name.trim())) {
      addError('invalid_first_name');
      return false;
    }
    if (!validateEmailExtensive(contactEmail)) {
      addError('invalid_email');
      return false;
    }

    return true;
  };

  const handleAddNewUser = () => {
    if (formType === FORM_TYPE.ADD_USER && !userCreated) {
      setShowErrors(false);
      if (!checkUserInfo()) {
        setShowErrors(true);
        return;
      }

      setLoading(true);
      let data = {};
      if (isSupplier()) {
        data = {
          contact_first_name: state.contact_first_name,
          contact_last_name: state.contact_last_name,
          contact_email: contactEmail,
        };
      } else {
        data = {
          ...state,
          contact_email: state.contact_email.trim(),
          active: "1",
        };
        if (!isTeam()) {
          data['feature_id'] = companyFeatureId;
        }
      }

      const invalidEmailErrors = ['existing_email', 'invalid_email_domain', 'invalid_email'];

      if (isSupplier()) {
        oauth('POST', 'user-invitations', data)
          .then(({ json }) => {
            dispatch(createLoadUsers(
              identity.company_id,
              identity.company_type,
              true,
              { include_inactive: true, with_user_profile_setup: true, },
            ));
            setLoading(false);
            setUserCreated(true);
          }).catch(err => {
            setLoading(false);
            setUserCreated(false);

            if (_.get(err, ['error_type'])) {
              setErrors(s => s.concat([
                _.get(err, ['error_type']),
              ]));
              if (invalidEmailErrors.includes(_.get(err, ['error_type']))) {
                return;
              }
            }

            if (_.get(err, ['error'])) {
              toast.error(_.get(err, ['error']));
            }
          });
        return;
      }

      Promise.resolve(
        dispatch(createAddUser(parseAddUserRequestData(identity.company_type, data)))
      ).then(action => {
        if (action.payload.error) {
          setLoading(false);
          if (action.payload.error_type) {
            setErrors(s => s.concat([action.payload.error_type]));
          }
          if (invalidEmailErrors.includes(action.payload.error_type)) {
            return;
          }
          toast.error(action.payload.error);
        } else {
          dispatch(incrementActiveUsers(1));
          setLoading(false);
          setUserCreated(true);

          const userActivationKey = action.payload.user.user_activation_key;
          const userActivationLink = formatActivationLink(userActivationKey);
          setUserActivationLink(userActivationLink);
        }
      });
    }
  };

  const handleReactivateUser = () => {
    if (!reactivateUserId) { return; }
    const allowedFields = [
      'contact_first_name',
      'contact_last_name',
      'contact_email',
      'contact_position',
      'role_id',
      'production_contact',
      'invoicing_contact',
    ];
    const userData = _.pick(editUserForm, allowedFields) || {};
    setLoading(true);
    const params = { ...userData, active: 1, };
    if (isTenant() && !isTeam()) {
      params['feature_id'] = companyFeatureId;
    }
    Promise.resolve(
      dispatch(createUpdateUserData(reactivateUserId, params))
    ).then(action => {
      if (action.payload.error) {
        toast.error(action.payload.error);
        if (action.payload.error_type) {
          setErrors(s => s.concat([action.payload.error_type]));
        }
      } else {
        setUserReactivated(true);
        if (isTenant() && !isTeam()) {
          dispatch(incrementActiveUsers(1));
        }
      }
      setLoading(false);
    });
  };

  const popupTitle = useMemo(() => {
    if (formType === FORM_TYPE.EDIT_USER && reactivateUserId) {
      if (userReactivated) {
        return 'User Reactivated';
      }
      return 'Reactivate User';
    } else if (userCreated) {
      if (isSupplier()) {
        return 'User Invited';
      }
      return 'User Added';
    }
    if (isSupplier()) {
      return 'Invite Coworker';
    }
    return 'Add User';
  }, [formType, userCreated, userReactivated, reactivateUserId, isSupplier]);

  return (
    <Popup
      id={'create-user-popu'}
      className={['popup', 'create-user-popup'].concat([formType + '-popup']).join(' ')}
      onClose={onClose}
      closeOnEsc
      style={{
        maxWidth: 600,
        borderRadius: 10,
        minHeight: 700,
        ...(userCreated || userReactivated ? {
          minHeight: 200,
          height: userActivationLink ? 250 : 200,
        } : {}),
      }}
      header={<PopupHeader id={'create-user-popup-header'}>
        <Col xs={8}>
          {!userCreated && !userReactivated && formType === FORM_TYPE.EDIT_USER && reactivateUserId &&
            <IconButton
              id={'go-back-btn'}
              style={{ marginRight: 10, }}
              variant='text'
              Icon={ArrowIcon}
              iconProps={{ direction: 'left' }}
              onClick={() => {
                setFormType(FORM_TYPE.REACTIVATE_USER);
              }}
          />}
          {!userCreated && !userReactivated &&
            <Avatar id={'popup-user-avatar'} pic={'/images/user-avatar3-120x120.png'} shape='circle' size='medium' style={{ verticalAlign: 'middle', }} />}
          {(userCreated || userReactivated) && <IconButton
            id={'create-user-checmark-btn'}
            style={{ marginRight: 10, }}
            variant='text'
            Icon={CheckmarkIcon}
            iconProps={{ direction: 'left', color: colors.secondary3.main, }}
          />}
          <Text id={'create-user-popup-title'} style={{ verticalAlign: 'middle', paddingLeft: 10, fontSize: 24, color: colors.neutrals['90'] }}>
            {popupTitle}
          </Text>
        </Col>
        <Col xs={4} end={1}>
          {(formType !== FORM_TYPE.EDIT_USER || userReactivated || userCreated) &&
            <Button
              id={'create-user-done-btn'}
              variant={userReactivated || userCreated ? 'primary' : 'secondary'}
              onClick={() => onClose && onClose()}
            >
              {userReactivated || userCreated ? 'Done' : 'Cancel'}
            </Button>}
        </Col>
      </PopupHeader>}
    >
      <Row>
        <ReactTooltip
          id="create-user-tooltip"
          place='right'
          delayShow={100}
        />
        <ReactTooltip
          id="edit-user-tooltip"
          place='right'
          delayShow={100}
        />
        {loading && <Col xs padded style={{ textAlign: 'center' }}>
          <Loading />
        </Col>}
        {!loading && <>
          {!userCreated && !userReactivated && <>
            {isSupplier() && <Col padded xs>
              <Text style={{
                fontFamily: fontFamilies.regular,
              }}>
                Please complete this form to invite a new user to your company. The user will be sent an email to complete their profile.
              </Text>
            </Col>}
            <Col padded xs>
              {!(formType === FORM_TYPE.EDIT_USER && reactivateUserId) && <TabBar id={'create-user-popup-tabs'} padded>
                <Tab
                  id={'create-user-tab'}
                  style={{ paddingBottom: 8, fontSize: 18, color: colors.neutrals['90'] }}
                  variant='secondary'
                  selected={formType === FORM_TYPE.ADD_USER}
                  onClick={() => {
                    setFormType(FORM_TYPE.ADD_USER);
                  }}>
                  New User
                </Tab>
                <Tab
                  id={'reactivate-user-tab'}
                  style={{ paddingBottom: 8, fontSize: 18, color: colors.neutrals['90'] }}
                  variant='secondary'
                  selected={formType === FORM_TYPE.REACTIVATE_USER}
                  onClick={() => {
                    setFormType(FORM_TYPE.REACTIVATE_USER);
                  }}>
                  Reactivate User
                </Tab>
              </TabBar>}
            </Col>
            {!invalidQuantity && !isTeam() && !isSupplier() && !(formType === FORM_TYPE.EDIT_USER && reactivateUserId) && <Col padded xs>
              <p id={'license-qty-text'}>
                <strong style={{ paddingRight: 5, }}>Using {minQuantity}/{quantity} licenses.</strong>
                An unused license will be assigned to this user.
              </p>
            </Col>}
            <Col padded xs>
              {formType === FORM_TYPE.ADD_USER && <CreateUserForm
                user={state}
                updateField={updateField}
                errors={errors}
                showErrors={showErrors}
              />}
              {formType === FORM_TYPE.REACTIVATE_USER && <ReactivateUsersForm
                reactivateUserId={reactivateUserId}
                setReactivateUserId={setReactivateUserId}
                onReactivateUser={() => setFormType(FORM_TYPE.EDIT_USER)}
                errors={errors}
                showErrors={showErrors}
              />}
              {formType === FORM_TYPE.EDIT_USER && reactivateUserId && <EditUserForm
                user={editUserForm}
                roleOptions={roleOptions}
                updateField={updateEditUserField}
                onUpdate={(data) => {
                  setEditUserForm(s => ({...s, ...data, }));
                }}
                shouldDispatchUpdate={false}
              />}
            </Col>
            <Col padded xs>
              {formType === FORM_TYPE.ADD_USER && <Button id={'add-new-user-btn'} variant='primary' onClick={handleAddNewUser}>
                {isSupplier() ? 'Send Invitation' : 'Add New User'}
              </Button>}
              {formType === FORM_TYPE.EDIT_USER && reactivateUserId && <Button id={'reactivate-user-btn'} variant='primary' onClick={handleReactivateUser}>Reactivate User</Button>}
            </Col>
          </>}
          {userCreated && <Col padded xs>
            <p id={'add-new-user-confirmation'}>
              {isSupplier()
                ? `Invitation sent to ${contactEmail}.`
                : `We sent an activation email to ${contactEmail}.`}
              <span style={{
                ...(isTeam() || isSupplier() ?  { display: 'none' } : {}),
                paddingLeft: 5,
              }}>You are now using {minQuantity}/{quantity} licenses.</span>
            </p>
            {userActivationLink && <>
                <p style={{marginBottom: 0, fontWeight: 'bold'}}>Activation link:</p>
                <Link as={'a'} href={userActivationLink} target='_blank' style={{ fontFamily: fontFamilies.regular, fontSize: 16, display: 'inline-block', width: 505 }}>{userActivationLink}</Link>
                <IconButton
                  mt={5}
                  variant='primary-light'
                  onClick={e => { navigator.clipboard.writeText(userActivationLink); }}
                  Icon={<ClipboardIcon />}
                />
              </>
            }
          </Col>}
          {userReactivated && <Col padded xs>
            <p id={'reactivate-user-confirmation'}>
              {_.get(users, [reactivateUserId, 'contact_email']) || 'User'} has been successfully reactivated.
              <span style={{
                ...(isTeam() || isSupplier() ?  { display: 'none' } : {}),
                paddingLeft: 5,
              }}>You are now using {minQuantity}/{quantity} licenses.</span>
            </p>
          </Col>}
        </>}
      </Row>
    </Popup>
  );
};

export default CreateUserPopup;
