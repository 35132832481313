import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, LabeledInput, IconButton } from '@commonsku/styles';
import { getActiveUsersList } from '../../../selectors/users';
import { getFeatureIdByName, getSocialFeatureId } from '../../../selectors';
import AddGroupUserRow from './AddGroupUserRow';

const AddGroupForm = React.memo((props) => {
  const {
    group,
    updateGroupName,
    updateGroupUser,
  } = props;

  const usersList = useSelector(s => {
    return getActiveUsersList(s).filter(
      v => ![
        getSocialFeatureId(s),
        getFeatureIdByName(s, 'DORMANT'),
      ].includes(v.feature_id)
    );
  });

  if (!group) {
    return null;
  }

  return (
    <Row id="add-group-form">
      <Col xs>
        <LabeledInput
          id="team_name"
          label='Group name (required)'
          value={group.team_name}
          onChange={e => updateGroupName(e.target.value)}
          style={{ width: 324 }}
        />
      </Col>
      <Col xs>
        {usersList.map((u, i) => {
          const foundIdx = group.team_users.findIndex(v => v === u.user_id);
          return (
            <AddGroupUserRow
              key={`team-user-edit-${i}`}
              user={u}
              foundIndex={foundIdx}
              updateGroupUser={() => updateGroupUser(foundIdx, u.user_id)}
            />
          );
        })}
      </Col>
    </Row>
  );
});

export default AddGroupForm;
