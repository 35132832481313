import _ from 'lodash';
import React, { useMemo } from 'react';
import { Avatar, Button, Col, colors, fontFamilies, LabeledRadio, Row, Text } from '@commonsku/styles';
import { useSelector } from 'react-redux';
import { getUsers } from '../../../selectors/users';
import { createSelector } from 'reselect';
import { getSocialFeatureId } from '../../../selectors';

const selectInactiveUsers = createSelector(
  [
    getUsers,
    getSocialFeatureId,
  ],
  (s, socialFeatureId) => {
    return Object.values(s || {}).filter(
      v => +v.active === 0
        || v.feature_id === socialFeatureId
    );
  }
);

const ReactivateUsersForm = React.memo((props) => {
  const {
    reactivateUserId,
    setReactivateUserId,
    onReactivateUser,
  } = props;
  const inactiveUsers = useSelector(selectInactiveUsers);
  const reactivateUserEmail = useMemo(
    () => reactivateUserId ? (_.get(
      _.find(inactiveUsers, v => v.user_id === reactivateUserId),
      ['login_name']
    ) || '') : '',
    [inactiveUsers, reactivateUserId]
  );

  return <Row id={'reactivate-user-form'}>
    <Col padded xs style={{ paddingBottom: 36, }}>
      <Button
        id={'reactivate-user-btn'}
        variant={reactivateUserId ? 'primary' : 'disabled'}
        size='medium'
        onClick={onReactivateUser}
        style={{ marginRight: 10, verticalAlign: 'middle' }}
      >
        Reactivate
      </Button>
      <span id={'reactivate-user-text'} style={{ display: 'inline' }}>
        {!reactivateUserId
          ? 'Select a user to reactivate'
          : `Click to reactivate ${reactivateUserEmail}`}
      </span>
    </Col>
    <Col padded xs>
      <Row className={'reactivate-user-list'} style={{ maxHeight: 350, overflow: 'auto' }}>
        {inactiveUsers.map((user, i) => (
          <Col
            className={'reactivate-user-row'}
            id={"reactivate-user-row-"+i}
            padded
            xs
            key={`activate-user-${i}`}
            onClick={() => setReactivateUserId(user.user_id)}
            style={{ cursor: 'pointer', paddingBottom: 32, }}
          >
            <Row>
              <Col xs={1}>
                <LabeledRadio
                  className={'reactivate-user-id-input'}
                  label=''
                  value={reactivateUserId === user.user_id}
                  checked={reactivateUserId === user.user_id}
                  onChange={() => setReactivateUserId(user.user_id)}
                />
              </Col>
              <Col xs={1.4}>
                <Avatar
                  className={'reactivate-user-avatar'}
                  pic={_.get(user, ['user_image_paths', 'small']) || '/images/user-avatar3-120x120.png'}
                  shape='circle'
                  size='medium'
                  style={{ verticalAlign: 'middle', }}
                />
              </Col>
              <Col xs={4} style={{ alignSelf: 'center', }}>
                <Text className={'reactivate-user-name'} style={{ overflowWrap: 'break-word', }}>{`${user.contact_first_name} ${user.contact_last_name}`.trim()}</Text>
              </Col>
              <Col xs={5} style={{ alignSelf: 'center', }}>
                <Text className={'reactivate-user-email'} style={{ overflowWrap: 'break-word', fontFamily: fontFamilies.regular }}>{user.contact_email}</Text>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </Col>
  </Row>;
});

export default ReactivateUsersForm;
