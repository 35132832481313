import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalStyle as BaseGlobalStyle, Theme, themeOptions, colors } from '@commonsku/styles';
import Header from './Header';
import MainSection from './MainSection';
import ErrorBoundary from '../components/ErrorBoundary';
import ReportTabsContainer from '../components/ReportTabsContainer';
import UserManagement from '../components/user-management';
import { getPopups } from '../selectors';
import Overlay from '../components/Overlay';
import withReducers from '../store/withReducers';
import { useIdentity } from '../hooks';
import { mentionTextareaStyles, SKUFontDefinitionStyles } from '../components/helpers/createGlobalStyle';
import { reducer as companyEmailDomainsReducer } from '../redux/company_email_domains';
import teamsReducer from '../redux/teams';
import stripeReducer from '../redux/stripe';
import permissionsReducer from '../redux/permissions';

const additionalGlobalStyle = (p) => `
${SKUFontDefinitionStyles}
${mentionTextareaStyles(p)}

body {
  background: ${colors.neutrals['20']};
}

.csku-styles {
  font-family: ${(p.theme && p.theme.fontFamily) || "'skufont-regular', 'sans-serif', 'Roboto'"};
}

.user-management-list .tr-group .tr {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
}

.user-management-list .tr-group .tr-sub {
  background: ${colors.primary1['20']};
  padding-bottom: 140px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 180px;
  }
}

.__react_component_tooltip {
  pointer-events: auto !important;
}

.user-management-list .tr-group .tr .td {
  align-self: center;
}

.defaultMenuSelect.commonsku-styles-select__menu {
  width: 150px;
}

.page .cs-nav li a {
  color: ${colors.teal.main};
  font-family: ${(themeOptions.fontFamilies.regular)};

  &:hover {
    border-bottom: 3px solid ${colors.teal.main};
  }
}

.popup.create-user-popup.REACTIVATE_USER-popup .popup-content {
  overflow-y: hidden;
}

#edit-user-popup.edit-user-popup-activated {
  min-height: 200px;
  height: 200px;
}

@media screen and (max-height: 700px) {
  .edit-user-popup-full {
    height: 90%;
    min-height: 90%;
  }
}
@media screen and (min-height: 700px) {
  .edit-user-popup-full {
    min-height: 700px;
  }
}

#edit-user-form + div #deactivate-user-btn:hover {
  background: ${colors.errors['10']};
  border-color: ${colors.errors['10']};
}
`;

export const GlobalStyle = (p) =>
  <BaseGlobalStyle {...p} additionalStyles={additionalGlobalStyle} />;

const AdminSettingsNewApp = () => {
  const popups = useSelector(getPopups);
  const identity = useIdentity();

  return (
    <Theme theme={themeOptions}>
      <GlobalStyle theme={themeOptions} />
      <ErrorBoundary>
        <Header></Header>
        <MainSection popups={popups}>
          <div className="main-content" style={{ paddingRight: '12px', background: colors.neutrals['20'] }}>
            {/* <ReportTabsContainer
              page="admin"
              csku={false}
              style={{ paddingTop: 7, paddingBottom: 0, }}
              className="page"
            /> */}
            <div className="row full-width" style={{ paddingLeft: '1.25rem' }}>
              <UserManagement />
            </div>
          </div>
        </MainSection>
        <Overlay popups={popups} />
      </ErrorBoundary>
    </Theme>
  );
};

export default withReducers(AdminSettingsNewApp, {
  company_email_domains: companyEmailDomainsReducer,
  teams: teamsReducer,
  stripe: stripeReducer,
  permissions: permissionsReducer,
}, true);
