import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { oauth } from '../utils';

const initialState = {};

const slice = createSlice({
  name: 'company_email_domains',
  initialState,
  reducers: {
    setCompanyEmailDomains(state, action) {
        return action.payload;
    },
    fetchCompanyEmailDomainsSuccess(state, action) {
        return {
            ...state,
            ...action.payload.reduce((acc, v) => {
                let id = v.tenant_email_domain_id;
                if (v.supplier_email_domain_id) {
                    id = v.supplier_email_domain_id;
                }

                return { ...acc, [id]: v, };
            }, {}),
        };
    },
    updateCompanyEmailDomainSuccess(state, action) {
        let id = action.payload.company_email_domain_id;
        if (!id && action.payload.tenant_email_domain_id) {
            id = action.payload.tenant_email_domain_id;
        } else if (!id && action.payload.supplier_email_domain_id) {
            id = action.payload.supplier_email_domain_id;
        }
        return {
            ...state,
            [id]: {
                ...(state[id] || {}),
                ...action.payload,
            },
        };
    },
    deleteCompanyEmailDomainSuccess(state, action) {
        const newState = { ...state };
        delete newState[action.payload];
        return newState;
    },
  },
});

/**
 * fetch company email domains
 *
 * @param {string} company_id
 * @param {'DISTRIBUTOR' | 'SUPPLIER' | 'CLIENT'} company_type
 * @param {{
 *  email_domain: string,
 * }} params request params
 * @returns {object | null} user object or null if failure
 */
export const fetchCompanyEmailDomains = (company_id, company_type, params = {}, addToQueue=true) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('GET', 'company-email-domain', { ...params, company_id, company_type }, null, null, null, addToQueue);

        const data = _.get(json, 'company_email_domains', []);
        dispatch(actions.fetchCompanyEmailDomainsSuccess(data));
        return data;
    } catch (error) {
        console.error('ERROR|fetchCompanyEmailDomains| ', error);
        let err = error.json.error;
        if (Array.isArray(err) && err.length > 0) {
            err = err[0];
        }
        return { error: err || 'Unable to get company email domains' };
    }
};

/**
 *
 * @param {{
 *  email_domain: string,
 *  company_type: 'DISTRIBUTOR' | 'SUPPLIER' | 'CLIENT',
 *  company_id: string,
 * }} params request params
 * @returns {object | null} user object or null if failure
 */
export const createCompanyEmailDomains = (params = {}) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('POST', 'company-email-domain', params);

        const data = _.get(json, 'company_email_domain', {});
        dispatch(actions.updateCompanyEmailDomainSuccess(data));
        return data;
    } catch (error) {
        console.error('ERROR|createCompanyEmailDomains| ', error);
        let err = error.json.error;
        if (Array.isArray(err) && err.length > 0) {
            err = err[0];
        }
        return { error: err || 'Unable to add email domain' };
    }
};

export const updateCompanyEmailDomains = (company_email_domain_id, company_type, email_domain) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('PUT', `company-email-domain/${company_email_domain_id}`, {
            company_type,
            email_domain,
        });

        const data = _.get(json, 'company_email_domain', {});
        dispatch(actions.updateCompanyEmailDomainSuccess({
            ...data,
            company_email_domain_id: company_email_domain_id,
        }));
        return data;
    } catch (error) {
        console.error('ERROR|updateCompanyEmailDomains| ', error);
        let err = error.json.error;
        if (Array.isArray(err) && err.length > 0) {
            err = err[0];
        }
        return { error: err || 'Unable to update email domain' };
    }
};

export const deleteCompanyEmailDomain = (company_email_domain_id, company_type) => async (dispatch, getState) => {
    try {
        await oauth('DELETE', `company-email-domain/${company_email_domain_id}`, { company_type });
        dispatch(actions.deleteCompanyEmailDomainSuccess(company_email_domain_id));
        return company_email_domain_id;
    } catch (error) {
        console.error('ERROR|deleteCompanyEmailDomain| ', error);
        let err = error.json.error;
        if (Array.isArray(err) && err.length > 0) {
            err = err[0];
        }
        return { error: err || 'Unable to delete email domain' };
    }
};

const getCompanies = s => _.get(s, 'companies', {});
export const selectByCompanyId = createSelector(
    [
        s => Object.values(s.company_email_domains || {}),
        (s, companyId) => companyId,
    ],
    (s, companyId) => s.filter(v => (v.tenant_id === companyId || v.supplier_id === companyId))
);
export const selectByIdentityCompanyId = createSelector(
    s => ({
        companyId: s.identity.company_id,
        companyType: s.identity.company_type,
        domains: Object.values(s.company_email_domains || {}),
    }),
    (s) => s.domains.filter(
        v => (v.tenant_id === s.companyId || v.supplier_id === s.companyId)
    )
);

export const selectors = {
    companies: createSelector(getCompanies, s => s),
    byId: createSelector([getCompanies, (s, id) => id], (s, id) => s[id]),
};

export const actions = slice.actions;
export const reducer = slice.reducer;

export default slice;
