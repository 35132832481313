import _ from 'lodash';
import React from 'react';
import { colors, fontFamilies, Text, } from '@commonsku/styles';
import { useSelector } from 'react-redux';
import { getRoleById } from '../../../../selectors';
import {
  checkUserIsCollborateChampion,
  checkUserLicenseInActive,
  checkUserLicensePending,
  checkUserProfileSetup,
} from '../../utils';

const UserRole = (props) => {
  const { row, style={}, } = props;
  const role = useSelector(s => getRoleById(s, row.original.role_id));
  const isProfileSetup = checkUserProfileSetup(row.original);
  const isLicensePending = checkUserLicensePending(row.original);
  const isLicenseInactive = checkUserLicenseInActive(row.original);

  const color = isProfileSetup && (''+row.original.active) === '1'
    ? colors.neutrals['90']
    : colors.neutrals['60'];

  const showStar = checkUserIsCollborateChampion(row.original);
  return (
    <div className='user-role-col' style={{...style, overflowWrap: 'break-word', color }}>
      {!isLicensePending && <p
        style={{ color, marginBottom: 0, width: 'fit-content' }}
        data-html={true}
        data-tip={`<span style="font-size: 16px; font-family: ${fontFamilies.regular}">User role</span>`}
        data-for={"user-details-tooltip"}
        data-effect='solid'
      >
        {_.get(role, ['role_name']) || ''}
        {showStar && <span style={{ paddingLeft: 5 }} dangerouslySetInnerHTML={{__html: '&star;'}} />}
      </p>}
      {isLicensePending && <Text style={{
        color: !isLicenseInactive ? colors.teal['65'] : colors.neutrals['60'],
        marginBottom: 0
      }}>
        {_.get(row.original, ['licenses_used']) || ''} / {_.get(row.original, ['total_licenses']) || ''}
      </Text>}
    </div>
  );
};

export default UserRole;
