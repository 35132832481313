import _ from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AlertNotification, Col, colors, fontFamilies, LabeledCheckbox, LabeledIconInput, LabeledInput, LabeledRadio, MailIcon, Row, Text } from '@commonsku/styles';
import { LabeledSelect } from '../../helpers';
import { getRoleOptions } from '../../../selectors';
import { selectByIdentityCompanyId } from '../../../redux/company_email_domains';
import { getIdentityUtils, validateEmail, validateEmailExtensive } from '../../../utils';
import { useIdentity } from '../../../hooks';

const CreateUserForm = React.memo((props) => {
  const {
    user,
    updateField,
    isDisabled = false,
    errors = [],
    showErrors = false,
  } = props;

  const emailDomains = useSelector(selectByIdentityCompanyId);
  const identity = useIdentity();
  const { isSupplier } = getIdentityUtils(identity);
  const emailDomainOptions = emailDomains.map(v => ({
    label: v.email_domain,
    value: v.email_domain,
  }));

  const isProductionContact = (_.get(user, ['production_contact'], '0') + '') === '1';
  const isInvoicingContact = (_.get(user, ['invoicing_contact'], '0') + '') === '1';
  const roleOptions = useSelector(getRoleOptions);

  const checkHasErrors = (errs=[]) => {
    return errors && errors.filter(e => errs.includes(e)).length > 0;
  };
  const hasEmailErrors = checkHasErrors(['existing_email', 'invalid_email_domain', 'invalid_email']);
  const hasFirsNameError = checkHasErrors(['invalid_first_name']);
  const hasRoleError = checkHasErrors(['invalid_role']);

  if (isSupplier()) {
    return (
      <Row id='create-user-form'>
        <Col xs>
          <LabeledInput
            id='contact_first_name'
            label='First name'
            name='contact_first_name'
            placeholder='First Name'
            value={user.contact_first_name}
            onChange={e => updateField('contact_first_name', e.target.value)}
            disabled={isDisabled}
            error={showErrors && !user.contact_first_name.trim()}
          />
        </Col>
        {(showErrors && (hasFirsNameError || !user.contact_first_name)) ? <Col xs>
          <AlertNotification alertType='error' id='create-user-alert-errors'>
            Please provide first name for the user.
          </AlertNotification>
        </Col> : null}
        <Col xs>
            <LabeledInput
              id='contact_last_name'
              label='Last name'
              name='contact_last_name'
              value={user.contact_last_name}
              placeholder='Last Name'
              onChange={e => updateField('contact_last_name', e.target.value)}
              disabled={isDisabled}
            />
        </Col>
        <Col xs>
          <Row>
            <Col xs sm={7.5} style={{ paddingTop: '0.5rem', }}>
              <LabeledInput
                id='username'
                label='Username'
                value={user.contact_email_username}
                placeholder='Username'
                onChange={e => updateField('contact_email_username', e.target.value)}
                error={showErrors && !user.contact_email_username.trim()}
                style={{ marginBottom: 14 }}
                disabled={isDisabled}
              />
            </Col>
            <Col xs={false} sm={0.75} style={{ paddingTop: '0.75rem', alignSelf: 'center', textAlign: 'center' }}>
              <Text style={{ fontFamily: fontFamilies.regular, fontSize: 24 }}>
                @
              </Text>
            </Col>
            <Col xs sm={3.5}>
              <LabeledSelect
                id='contact_email_domain'
                name="contact_email_domain"
                label='Email domain'
                options={emailDomainOptions}
                value={user.contact_email_domain}
                placeholder='Email domain'
                onChange={e => updateField('contact_email_domain', e ? e.value : '')}
                error={showErrors && !user.contact_email_domain.trim()}
                style={{ marginBottom: 14 }}
                disabled={isDisabled}
              />
            </Col>
          </Row>
        </Col>
        {hasEmailErrors ? <Col xs>
          <AlertNotification alertType='error' id='create-user-alert-errors'>
          {!checkHasErrors(['existing_email', 'invalid_email'])
            ? 'You cannot use this email domain.'
            : 'This email is either invalid or assigned to another user. Please use a unique login email.'}
          </AlertNotification>
        </Col> : null}
      </Row>
    );
  }

  return (
    <Row id='create-user-form'>
      <Col xs>
          <LabeledInput
            id='contact_first_name'
            label='First name'
            name='contact_first_name'
            placeholder='First Name'
            value={user.contact_first_name}
            onChange={e => updateField('contact_first_name', e.target.value)}
            disabled={isDisabled}
            error={showErrors && !user.contact_first_name.trim()}
          />
      </Col>
      {(showErrors && (hasFirsNameError || !user.contact_first_name)) ? <Col xs>
        <AlertNotification alertType='error' id='create-user-alert-errors'>
          Please provide first name for the user.
        </AlertNotification>
      </Col> : null}
      <Col xs>
          <LabeledInput
            id='contact_last_name'
            label='Last name'
            name='contact_last_name'
            value={user.contact_last_name}
            placeholder='Last Name'
            onChange={e => updateField('contact_last_name', e.target.value)}
            disabled={isDisabled}
          />
      </Col>
      <Col xs>
        <LabeledIconInput
          id='contact_email'
          label='Email'
          value={user.contact_email}
          placeholder='name@company.com'
          Icon={<MailIcon />}
          onChange={e => updateField('contact_email', e.target.value)}
          error={showErrors && (!user.contact_email.trim() || !validateEmailExtensive(user.contact_email))}
          style={{ marginBottom: 14 }}
          disabled={isDisabled}
        />
      </Col>
      {hasEmailErrors ? <Col xs>
        <AlertNotification alertType='error' id='create-user-alert-errors'>
        {!checkHasErrors(['existing_email', 'invalid_email'])
          ? 'You cannot use this email domain.'
          : 'This email is either invalid or assigned to another user. Please use a unique login email.'}
        </AlertNotification>
      </Col> : null}
      <Col xs>
        <LabeledInput
          id='contact_position'
          label='Position'
          placeholder='e.g. Sales Rep'
          value={user.contact_position}
          onChange={e => updateField('contact_position', e.target.value)}
          disabled={isDisabled}
        />
      </Col>
      <Col xs>
        <Text as="p">Role</Text>
        {roleOptions.map((v, i) => (
          <LabeledRadio
            key={'role-radio-'+i}
            name={'role_id'}
            id={'user-role-'+i}
            label={v.label}
            checked={user.role_id === v.value}
            value={user.role_id === v.value}
            onChange={(e) => updateField('role_id', v.value)}
            labelStyle={{ width: 110 }}
            disabled={isDisabled}
            radioColor={showErrors && !user.role_id ? colors.errors.main : colors.teal.main}
          />
        ))}
      </Col>
      {(showErrors && (hasRoleError || !user.role_id)) ? <Col xs>
        <AlertNotification alertType='error' id='create-user-alert-errors'>
          Please select a role for the user.
        </AlertNotification>
      </Col> : null}
      <Col xs pt={12}>
        <Text as="p" style={{ marginBottom: 0 }}>Contact</Text>
        <Row>
          <Col xs sm={6}>
            <LabeledCheckbox
              id={'production_contact'}
              label="Production Contact"
              value={isProductionContact}
              checked={isProductionContact}
              onChange={() => updateField('production_contact', isProductionContact ? 0 : 1)}
              labelStyle={{ paddingLeft: 0, paddingTop: 14, }}
              disabled={isDisabled}
              labelProps={{
                'data-html': true,
                'data-place': 'right',
                'data-tip': `<span style="font-size: 16px;">This is your team's <a style="color: #fff; text-decoration: underline;" target="_blank" href="https://help.commonsku.com/knowledge/project-templates#reps">default production rep</a> (should only be assigned to one user)</span>`,
                'data-for': 'create-user-tooltip',
                'data-delay-hide': '100',
              }}
            />
          </Col>
          <Col xs sm={6}>
            <LabeledCheckbox
              id={'invoicing_contact'}
              label='Invoicing Contact'
              value={isInvoicingContact}
              checked={isInvoicingContact}
              onChange={() => updateField('invoicing_contact', isInvoicingContact ? 0 : 1)}
              labelStyle={{ paddingLeft: 0, paddingTop: 14, }}
              disabled={isDisabled}
              labelProps={{
                'data-html': true,
                'data-place': 'top',
                'data-tip': `<span style="font-size: 16px;">This is your team's <a style="color: #fff; text-decoration: underline;" target="_blank" href="https://help.commonsku.com/knowledge/project-templates#reps">default invoicing rep</a> (should only be assigned to one user)</span>`,
                'data-for': 'create-user-tooltip',
                'data-delay-hide': '100',
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

export default CreateUserForm;
