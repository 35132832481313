import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Avatar, IconButton, XIcon, LightIndicator, ConfirmPopup } from '@commonsku/styles';
import { deleteTeamUser } from '../../../redux/teams';

const EditGroupUserRow = React.memo((props) => {
  const {
    user,
    teamId,
    canDelete=true,
  } = props;
  const dispatch = useDispatch();
  const isActive = (user.active + '') === '1';
  const [hovering, setHovering] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <Row
      className="edit-group-user-row"
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {showConfirm && <ConfirmPopup
        className="edit-group-user-row-confirm-popup"
        confirmText={!canDelete ? 'Cannot delete user. Atleast one user is required' : undefined}
        disableDelete={!canDelete}
        onClose={() => setShowConfirm(false)}
        onDelete={() => {
          if (!canDelete) { return; }
          Promise.resolve(dispatch(deleteTeamUser(teamId, user.user_id)))
            .then(() => {
              setShowConfirm(false);
            });
        }}
      />}
      <Col xs sm={4}>
        <span style={{ paddingRight: 10, }}>
          <IconButton
            variant='error'
            Icon={<XIcon color='#fff' altText='' />}
            size='medium'
            style={{
              visibility: hovering ? 'visible' : 'hidden',
            }}
            onClick={() => setShowConfirm(true)}
            className="edit-group-user-row-delete-btn"
          />
        </span>
        <span>
          <Avatar pic={'/images/user-avatar3-120x120.png'} shape='circle' size='medium' style={{ verticalAlign: 'middle', }} />
          <span style={{ verticalAlign: 'middle', paddingLeft: 10, }}>{user.contact_full_name}</span>
        </span>
      </Col>
      <Col xs sm={4}>
        <p style={{ verticalAlign: 'middle', paddingLeft: 10, }}>{user.contact_email}</p>
      </Col>
      <Col xs sm={4}>
      <LightIndicator
        on={isActive}
        name={isActive ? 'Active' : 'Inactive'}
        className="edit-group-user-row-active-status"
      />
      </Col>
    </Row>
  );
});

export default EditGroupUserRow;
