import React from 'react';
import { Row, Col, Avatar, LabeledCheckbox } from '@commonsku/styles';

const GroupUserRow = React.memo((props) => {
  const {
    foundIndex,
    user,
    updateGroupUser,
  } = props;

  return (
    <Row className="group-user-row">
      <Col xs sm={4}>
        <span>
          <LabeledCheckbox
            className="group-user-checked"
            label=''
            value={foundIndex !== -1}
            checked={foundIndex !== -1}
            onChange={updateGroupUser}
          />
        </span>
        <span>
          <Avatar className="group-user-avatar" pic={'/images/user-avatar3-120x120.png'} shape='circle' size='medium' style={{ verticalAlign: 'middle', }} />
          <span className="group-user-name" style={{ verticalAlign: 'middle', paddingLeft: 10, }}>{user.contact_full_name}</span>
        </span>
      </Col>
      <Col xs sm={8}>
        <p className="group-user-name" style={{ verticalAlign: 'middle', paddingLeft: 10, }}>{user.contact_email}</p>
      </Col>
    </Row>
  );
});

export default GroupUserRow;
