import { Button, Col, colors, Popup, PopupHeader, Row, Tab, TabBar, Text, themeOptions } from '@commonsku/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getActiveUsersList, getInactiveUsersList, userOptionMapFunc } from '../../../selectors/users';
import { oauth } from '../../../utils';
import { LabeledSelect } from '../../helpers';
import { reassignUserClients } from '../utils';

const TAB_TYPE = {
  ACTIVE_REPS: 'ACTIVE_REPS',
  INACTIVE_REPS: 'INACTIVE_REPS',
};

const ReassignClientsPopup = (props) => {
  const {
    onClose,
  } = props;
  const activeUsers = useSelector(s => getActiveUsersList(s).map(userOptionMapFunc));
  const inactiveUsers = useSelector(s => getInactiveUsersList(s).map(userOptionMapFunc));

  const [selectedTab, setSelectedTab] = useState(TAB_TYPE.ACTIVE_REPS);
  const [state, setState] = useState({from: '', to: ''});
  const [clientsCount, setClientsCount] = useState(0);
  const [showErrors, setShowErrors] = useState(false);

  const fromUsers = useMemo(() => {
    const result = selectedTab === TAB_TYPE.ACTIVE_REPS ? activeUsers : inactiveUsers;
    if (!state.to) {
      return result;
    }
    return result.filter(v => v.value !== state.to);
  }, [state.to, selectedTab, activeUsers, inactiveUsers]);

  const toUsers = useMemo(() => {
    const result = activeUsers;
    if (!state.from) {
      return result;
    }
    return result.filter(v => v.value !== state.from);
  }, [state.from, activeUsers]);

  const onReassign = () => {
    if (!state.from || !state.to) {
      setShowErrors(true);
      return;
    }
    setShowErrors(false);
    reassignUserClients(state.from, state.to).then(res => {
      if (res.error) {
        toast.error(res.error);
      } else {
        onClose && onClose();
      }
    });
  };

  useEffect(() => {
    if (!state.from) {
      setClientsCount(0);
      return;
    }

    oauth('POST', 'client', {
      action: 'FETCH-CLIENTS',
      sales_rep_id: state.from,
    }).then(({ json }) => {
      setClientsCount(json.clients_count);
    });
  }, [state.from]);

  useEffect(() => {
    setState({from: '', to: ''});
  }, [selectedTab]);

  return (
    <Popup
      onClose={onClose}
      closeOnEsc
      style={{ maxWidth: 580, borderRadius: 10, maxHeight: 450, }}
      header={<PopupHeader>
        <Col xs>
          <Text style={{ verticalAlign: 'middle', paddingLeft: 10, fontSize: 24, color: colors.neutrals['90'] }}>
            Reassign Clients
          </Text>
        </Col>
      </PopupHeader>}
    >
      <Row>
        <Col xs padded>
          <TabBar>
            <Tab
              selected={selectedTab === TAB_TYPE.ACTIVE_REPS}
              variant='secondary'
              onClick={() => setSelectedTab(TAB_TYPE.ACTIVE_REPS)}
            >Active Reps</Tab>
            <Tab
              selected={selectedTab === TAB_TYPE.INACTIVE_REPS}
              variant='secondary'
              onClick={() => setSelectedTab(TAB_TYPE.INACTIVE_REPS)}
            >Inactive Reps With Clients</Tab>
          </TabBar>
        </Col>
        <Col xs padded>
          <LabeledSelect
            inPopup
            options={fromUsers}
            value={state.from}
            label="Reassign clients from..."
            onChange={v => setState(s => ({...s, from: v.value}))}
            error={showErrors && !state.from}
          />
          <Text
            as="p"
            style={{
              display: !state.from ? 'none' : 'block',
              fontFamily: themeOptions.fontFamilies.regular,
              paddingTop: 10,
              margin: 0,
            }}
          >{clientsCount} clients</Text>
        </Col>
        <Col xs padded>
          <LabeledSelect
            inPopup
            options={toUsers}
            value={state.to}
            label="Reassign clients to..."
            onChange={v => setState(s => ({...s, to: v.value}))}
            error={showErrors && !state.to}
          />
        </Col>
        <Col xs sm={6} padded xsStyle={{ textAlign: 'center' }} smStyle={{ textAlign: 'left' }}>
          <Button style={{ width: '100%' }} variant='secondary' onClick={onClose}>Cancel</Button>
        </Col>
        <Col xs sm={6} padded xsStyle={{ textAlign: 'center' }} smStyle={{ textAlign: 'left' }}>
          <Button style={{ width: '100%' }} variant='primary' onClick={onReassign}>Reassign</Button>
        </Col>
      </Row>
    </Popup>
  );
};

export default ReassignClientsPopup;
