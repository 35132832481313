import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AlertNotification,
  ArrowIcon,
  Button,
  Col,
  colors,
  IconButton,
  Popup,
  PopupHeader,
  Row,
  Tab,
  TabBar,
  Text,
} from '@commonsku/styles';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createTeam, fetchTeams, selectTeamsList, updateTeam } from '../../redux/teams';
import GroupRow from './GroupRow';
import AddGroupForm from './add-group/AddGroupForm';
import EditGroupForm from './edit-group/EditGroupForm';

const TAB_TYPE = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const FORM_TYPE = {
  ADD_GROUP: 'ADD_GROUP',
  EDIT_GROUP: 'EDIT_GROUP',
};

function ManageGroupsPopup(props) {
  const {
    onClose,
  } = props;
  const dispatch = useDispatch();
  const teamsList = useSelector(selectTeamsList);

  const [formType, setFormType] = useState('');
  const [selectedTab, setSelectedTab] = useState(TAB_TYPE.ACTIVE);
  const [editGroupId, setEditGroupId] = useState('');
  const [errors, setErrors] = useState('');
  const [group, setGroup] = useState({
    team_type: "GROUP",
    team_name: '',
    team_users: [],
  });

  const resetGroup = useCallback(() => {
    setGroup({
      team_type: "GROUP",
      team_name: '',
      team_users: [],
    });
  }, []);

  const updateGroupName = useCallback((value) => {
    setGroup(s => ({ ...s, team_name: value }));
  }, []);

  const updateGroupUser = useCallback((index, user_id) => {
    setGroup(s => {
      let teamUsers = [];
      if (index !== -1) {
        // unchecked
        teamUsers = [
          ...s.team_users.slice(0, index),
          ...s.team_users.slice(index+1),
        ];
      } else {
        // checked
        teamUsers = s.team_users.concat([user_id]);
      }
      return {...s, team_users: teamUsers};
    });
  }, []);

  const tabGroups = useMemo(() => {
    const value = selectedTab === TAB_TYPE.ACTIVE ? '1' : '0';
    return teamsList.filter(v => ((''+v.active) === value));
  }, [teamsList, selectedTab]);

  useEffect(() => {
    dispatch(fetchTeams({team_type: 'GROUP', include_inactive: 1}));
  }, []);

  const onAddGroup = () => {
    if (!(group.team_name || '').trim()) {
      setErrors('Group name is required');
      return;
    } else if (!group.team_users.length) {
      setErrors('Atleast one user is required');
      return;
    }
    setErrors('');
    Promise.resolve(
      dispatch(createTeam(group))
    ).then(data => {
      if (data) {
        setFormType('');
        setEditGroupId('');
        resetGroup();
      } else {
        toast.error('unable to create group');
      }
    });
  };

  const handleDone = () => {
    if (formType) {
      if (formType === FORM_TYPE.ADD_GROUP) {
        onAddGroup();
        return;
      } else if (formType === FORM_TYPE.EDIT_GROUP) {
        setFormType('');
        setEditGroupId('');
        setErrors('');
        resetGroup();
      }
    }
    onClose && onClose();
  };

  const popupTitle = useMemo(() => {
    if (formType === FORM_TYPE.ADD_GROUP) {
      return 'Add Group';
    }
    if (formType === FORM_TYPE.EDIT_GROUP) {
      return 'Edit Group';
    }
    return 'Manage Groups';
  }, [formType]);

  return (
    <Popup
      id="manage-groups-popup"
      onClose={onClose}
      closeOnEsc
      style={{ maxWidth: 1200, borderRadius: 10, }}
      header={<PopupHeader id="manage-groups-popup-header">
        <Col xs={6}>
          {formType && <IconButton
            id="go-back-btn"
            variant='text'
            Icon={ArrowIcon}
            iconProps={{ direction: 'left' }}
            onClick={() => {
              setFormType('');
              setEditGroupId('');
              setErrors('');
              resetGroup();
            }}
          />}
          <Text id="manage-groups-popup-title" style={{ verticalAlign: 'middle', paddingLeft: 10, fontSize: 24, color: colors.neutrals['90'] }}>
            {popupTitle}
          </Text>
        </Col>
        <Col xs={6} end={1}>
          {!formType && <Button id="add-group-btn" style={{ marginRight: 10, }} variant='cta' onClick={() => {
            setEditGroupId('');
            setErrors('');
            resetGroup();
            setFormType(FORM_TYPE.ADD_GROUP);
          }}>
            + Add Group
          </Button>}
          <Button id="done-group-btn" variant='primary' onClick={handleDone}>
            {formType === FORM_TYPE.ADD_GROUP
              ? 'Add'
              : 'Done'}
          </Button>
        </Col>
      </PopupHeader>}
    >
      <Row>
        {!formType && <>
          <Col xs>
            <TabBar padded id="manage-groups-tabs">
              <Tab
                id="active-groups-tab"
                variant='secondary'
                selected={selectedTab === TAB_TYPE.ACTIVE}
                onClick={() => {
                  setSelectedTab(TAB_TYPE.ACTIVE);
                }}>
                Active
              </Tab>
              <Tab
                id="inactive-groups-tab"
                variant='secondary'
                selected={selectedTab === TAB_TYPE.INACTIVE}
                onClick={() => {
                  setSelectedTab(TAB_TYPE.INACTIVE);
                }}>
                Inactive
              </Tab>
            </TabBar>
          </Col>
          <Col xs padded className="groups-list">
            {tabGroups.map((v, i) => (
              <GroupRow
                key={`group-${i}`}
                group={v}
                onClickEdit={() => {
                  setFormType(FORM_TYPE.EDIT_GROUP);
                  setEditGroupId(v.team_id);
                }}
                onClickActivation={() => {
                  dispatch(updateTeam(v.team_id, {
                    active: (''+v.active) === '1' ? 0 : 1,
                    team_type: "GROUP",
                  }));
                }}
              />
            ))}
          </Col>
        </>}
        <Col xs padded>
          {errors && <Row className="groups-errors" style={{ marginBottom: 10, }}>
            <Col xs>
              <AlertNotification alertType='error' className="groups-error-alert">
                {errors}
              </AlertNotification>
            </Col>
          </Row>}
          {FORM_TYPE.ADD_GROUP === formType && <AddGroupForm
            group={group}
            updateGroupName={updateGroupName}
            updateGroupUser={updateGroupUser}
          />}
          {FORM_TYPE.EDIT_GROUP === formType && <EditGroupForm teamId={editGroupId} />}
        </Col>
      </Row>
    </Popup>
  );
}

export default ManageGroupsPopup;
