import _ from 'lodash';
import { oauth } from "../../utils";

export const USER_LICENSE_TYPE = {
  LICENSE_AVAILABLE: 'LICENSE_AVAILABLE',
  LICENSE_UNAVAILABLE: 'LICENSE_UNAVAILABLE',
};
export const checkUserLicensePending = (user={}) => [
    USER_LICENSE_TYPE.LICENSE_AVAILABLE,
    USER_LICENSE_TYPE.LICENSE_UNAVAILABLE
  ].includes(_.get(user, 'user_id'));
export const checkUserLicenseUnused = (user={}) =>
  _.get(user, 'user_id') === USER_LICENSE_TYPE.LICENSE_AVAILABLE;
export const checkUserLicenseInActive = (user={}) =>
  _.get(user, 'user_id') === USER_LICENSE_TYPE.LICENSE_UNAVAILABLE;

export const checkUserProfileSetup = (user={}) => (
  ('' + _.get(user, 'profile_setup')) === '1'
);

export const checkUser2Fa = (user={}) => ('' + _.get(user, 'security_2fa')) === '1';

export const checkUserIsCollborateChampion = (user={}) => {
  const is_collaborate_champion = user.is_collaborate_champion;
  return typeof is_collaborate_champion === 'boolean'
    ? is_collaborate_champion
    : ('1' === ( ''+is_collaborate_champion));
};

export const reassignUserClients = async (from, to) => {
  try {
    const res = await oauth('POST', 'client', {
      'from_rep_id': from,
      'to_rep_id': to,
      'action': 'REASSIGN'
    });
    if (res.json.error) {
      return { error: res.json.error };
    }
  } catch (error) {
    return {
      error: error.error
          || _.get(error, ['json', 'error'])
          || 'Unable to assign license to user'
    };
  }

  return {};
};

export const parseAddUserRequestData = (company_type, state) => {
  const address = _.get(state, ['address']) || {
    address_line_1: "",
    address_city: "",
    address_state: "",
    address_country: "",
    address_postal: "",
  };
  const result = {
    parent_type: company_type,
    contact_email: state.contact_email,
    contact_first_name: state.contact_first_name,
    contact_last_name: state.contact_last_name,
    contact_position: state.contact_position,
    invoicing_contact: state.invoicing_contact,
    production_contact: state.production_contact,
    role_id: state.role_id,
    divisions: [],
    address: address,
    address_city: address.address_city,
    address_country: address.address_country,
    address_line_1: address.address_line_1,
    address_postal: address.address_postal,
    address_state: address.address_state,
    client_rep_commission_rate_id: "",
    combined_rep_commission_rate_id: "",
    order_rep_commission_rate_id: "",
    sales_target: "",
    date_birthdate: "",
    date_startdate: "",
    user_email_signature: "",
  };

  if (state.active !== undefined && state.active !== null && state.active !== '') {
    result['active'] = state.active;
  }
  if (state.feature_id) {
    result['feature_id'] = state.feature_id;
  }
  return result;
};
