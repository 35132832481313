import _ from 'lodash';
import React from 'react';
import { Avatar, colors, Text, } from '@commonsku/styles';
import { checkUserLicenseInActive, checkUserLicensePending } from '../../utils';

const UserAvatar = (props) => {
  const { row, style={}, } = props;
  const userImage = _.get(row.original, ['user_image_paths', 'small']) || '/images/user-avatar3-120x120.png';
  const isLicensePending = checkUserLicensePending(row.original);
  const isLicenseInactive = checkUserLicenseInActive(row.original);

  return (
    <div className='user-avatar-col' style={{...style, overflowWrap: 'break-word', ...(isLicensePending ? { width: 250 } : {}), }}>
      {!isLicensePending && <Avatar pic={userImage} shape='circle' size='large' />}
      {isLicensePending &&
        <Text as="span"
          style={{
            fontWeight: 'bold',
            fontSize: 20,
            paddingLeft: 10,
            color: !isLicenseInactive ? colors.teal['65'] : colors.neutrals['60'],
            marginBottom: 0
          }}
        >{isLicenseInactive ? 'Inactive license' : 'Add user'}</Text>}
    </div>
  );
};

export default UserAvatar;
