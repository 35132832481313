import React from 'react';
import { Col } from '@commonsku/styles';
import { Select } from '../helpers';

const DefaultMenuSelect = React.memo((props) => {
  const {
    totalCols,
    rolePermissions,
    permissions,
    role,
    onChange,
    hasManagementSection,
  } = props;
  const rolePermIds = Object.keys(rolePermissions[role.role_id] ?? {});
  const permNames = permissions.filter(p => rolePermIds.includes(p.permission_id)).map(p => p.permission_name);
  const defaultMenuOptions = [
    {label: 'Sales', value: "SALES"},
    {label: 'Production', value: "PRODUCTION"},
    {label: 'Finance', value: "FINANCE"},
    {label: 'Connect', value: "CONNECT"},
  ];

  if (hasManagementSection) {
    defaultMenuOptions.push({label: 'Management', value: "MANAGEMENT"});
  }

  const value = (!hasManagementSection && role.default_menu === 'MANAGEMENT') ? 'SALES' : role.default_menu;

  const options = defaultMenuOptions.map(v => ({
    ...v,
    disabled: v.value !== 'CONNECT' && !permNames.includes('VIEW-' + v.value + '-MENU'),
  }));

  return (
    <Col
      xs={1}
      totalCols={totalCols}
      style={{ textAlign: 'center', paddingRight: 10, }}
      className="role-default-menu-col"
    >
      <Select
        inPopup
        options={options}
        value={value}
        isOptionDisabled={(option) => option.disabled}
        onChange={onChange}
        className="defaultMenuSelect"
        classNamePrefix="defaultMenuSelect commonsku-styles-select"
      />
    </Col>
  );
});

export default DefaultMenuSelect;
