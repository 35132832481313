import React from 'react';
import { colors, EditIcon, IconButton, } from '@commonsku/styles';
import { checkUserLicensePending } from '../../utils';

const UserEditButton = (props) => {
  const { row, column, style={}, resetList, toggleAllRowsExpanded, } = props;
  const isLicensePending = checkUserLicensePending(row.original);
  return (
    <div className='user-edit-btn-col' style={{...style, overflowWrap: 'break-word', textAlign: 'right', paddingRight: 10, }}>
      {!isLicensePending && <IconButton
        Icon={EditIcon}
        variant='primary'
        style={row.isExpanded ? {
          background: colors.primary1['80'],
          borderColor: colors.primary1['80'],
        } : {}}
        onClick={e => {
          column.toggleEditing && column.toggleEditing({row, resetList, toggleAllRowsExpanded});
        }}
      />}
    </div>
  );
};

export default UserEditButton;
