import React from 'react';
import { Col, colors, fontFamilies, Row } from '@commonsku/styles';
import RoleCheckbox from './RoleCheckbox';

const PermissionRow = React.memo((props) => {
  const {
    permission,
    roles,
    rolePermissions,
  } = props;

  return (
    <Row className="permission-row">
      <Col xs sm={3} style={{ paddingBottom: 10 }}>
        <p
          className="permission-row-label"
          style={{ color: '#000' }}
          data-html={true}
          data-tip={`<span
            style="font-size: 16px; font-family: ${fontFamilies.regular}; color: ${colors.neutrals['70']};"
          >${permission.permission_description}</span>`}
          data-for={"permission-tooltip"}
        >{permission.permission_label}</p>
      </Col>
      <Col xs sm={9} style={{ paddingBottom: 10 }}>
        <Row className="permission-roles-list">
          {roles.map((r, j) => (
            <RoleCheckbox
              key={`role-${permission.permission_id}-${j}`}
              totalCols={roles.length}
              rolePermissions={rolePermissions}
              role={r}
              permission={permission}
            />
          ))}
        </Row>
      </Col>
    </Row>
  );
});

export default PermissionRow;
