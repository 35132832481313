import _ from 'lodash';
import React from 'react';
import { Avatar, Row, Col, Button, EditIcon, IconButton, colors } from '@commonsku/styles';
import { useSelector } from 'react-redux';
import { getUsers } from '../../selectors/users';

const GroupRow = React.memo((props) => {
  const {
    group,
    onClickEdit,
  } = props;
  const users = useSelector(getUsers);
  const teamUser = _.get(group, ['team_users']) || [];

  return (
    <Row
      className="group-row"
      style={{
        background: colors.neutrals['20'],
        padding: 10,
        marginBottom: 10,
        borderRadius: 5,
      }}
    >
      <Col className="group-row-name" xs md={3.5}>
        <p style={{ padding: 10, fontWeight: 'bold' }}>{group.team_name}</p>
      </Col>
      <Col className="group-row-user-avatars-list" xs md={2.5}>
        {teamUser.slice(0, 3).map((u, j) => {
          const user = users[u.user_id];
          const userImage = _.get(user, ['user_image_paths', 'small']);
          return (
            <UserAvatar
              key={`team-user-${group.team_id}-${j}`}
              pic={userImage}
              label={u.contact_full_name || user.contact_full_name} />
          );
        })}
      </Col>
      <Col className="group-row-user-names-list" xs md={2.5}>
        {teamUser.slice(3, 5).map((u, j) => {
          const user = users[u.user_id];
          const userImage = _.get(user, ['user_image_paths', 'small']);
          return (
            <UserAvatar
              key={`team-user-${group.team_id}-${j + 3}`}
              pic={userImage}
              label={u.contact_full_name || user.contact_full_name} />
          );
        })}
        {teamUser.length === 6 && <UserAvatar
          pic={_.get(teamUser[5], ['user_image_paths', 'small'])}
          label={teamUser[5].contact_full_name || _.get(users, [teamUser[5].user_id, 'contact_full_name'])}
        />}
        {teamUser.length > 6 && <UserAvatar label={`${teamUser.length - 5} others`} />}
      </Col>
      <Col xs md={3.5} end={1} mdStyle="justify-content: flex-end; text-align: right;">
        <IconButton
          variant='primary'
          Icon={EditIcon}
          mr={10}
          mb={5}
          onClick={onClickEdit}
          className="group-row-edit-btn"
        />
      </Col>
    </Row>
  );
});

const UserAvatar = React.memo((props) => {
  const { pic, icon=true, label } = props;
  return (
    <div style={{ paddingBottom: 8, }}>
      <Avatar pic={pic} icon={icon} shape='circle' size='medium' style={{ verticalAlign: 'middle', background: colors.neutrals['50'] }} />
      <span style={{ verticalAlign: 'middle', paddingLeft: 10, }}>{label}</span>
    </div>
  );
});

export default GroupRow;
