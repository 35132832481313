import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, LabeledCheckbox } from '@commonsku/styles';
import { createRolePermission, removeRolePermission } from '../../redux/permissions';

const RoleCheckbox = React.memo((props) => {
  const {
    totalCols,
    rolePermissions,
    role,
    permission,
  } = props;
  const dispatch = useDispatch();
  const permVal = rolePermissions[role.role_id]?.[permission.permission_id];
  const [hasPerm, setHasPerm] = useState(permVal !== undefined);
  const isAdminRole = role.role_name.toLowerCase().trim() === 'admin';
  const isLimitedPerm = permission.permission_name === 'LIMITED-ACCESS';
  const isRestrictedPerm = permission.permission_name === 'RESTRICTED-ACCESS';
  const adminDisabledPerms = (isAdminRole && (isLimitedPerm || isRestrictedPerm) && !hasPerm);

  return (
    <Col xs={1} totalCols={totalCols} style={{ textAlign: 'center' }}>
      <LabeledCheckbox
        className="permission-role-checkbox"
        label=""
        checked={hasPerm}
        value={hasPerm}
        labelStyle={{ margin: 0, }}
        onChange={() => {
          if (hasPerm) {
            dispatch(removeRolePermission(role.role_id, permission.permission_id));
          } else {
            dispatch(createRolePermission(role.role_id, permission.permission_id));
          }
          setHasPerm(!hasPerm);
        }}
        readOnly
        disabled={adminDisabledPerms || ('' + permVal) === '1'}
        labelProps={{
          'data-for': adminDisabledPerms ? "permission-tooltip" : '',
          'data-tip': "We've removed this option, as it would limit admin access to essential features.",
          'data-place': 'top',
        }}
      />
    </Col>
  );
});

export default RoleCheckbox;
