import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowIcon, Avatar, Button, CheckmarkIcon, Col, colors, IconButton, Loading, Popup, PopupHeader, Row, Text } from '@commonsku/styles';
import { toast } from 'react-toastify';
import EditUserForm from './EditUserForm';
import { useDispatch, useSelector } from 'react-redux';
import { createAddUser, createUpdateUserData } from '../../../actions';
import { getFeatureIdByName, getRoleOptions } from '../../../selectors';
import { parseAddUserRequestData } from '../utils';
import DeactivateUserForm from './DeactivateUserForm';
import CreateUserForm from './CreateUserForm';
import { useIdentity } from '../../../hooks';
import {
  checkCanUpgradeUser,
  incrementActiveUsers,
  selectStripeActiveUsers,
  selectStripeCalculatedQuantity,
} from '../../../redux/stripe';
import ReactTooltip from '../../helpers/ReactTooltip';
import { rebuildTooltip } from '../../helpers';
import { getIdentityUtils, oauth } from '../../../utils';

const FORM_TYPE = {
  EDIT_USER: 'EDIT_USER',
  DEACTIVATE_USER: 'DEACTIVATE_USER',
  CREATE_USER: 'CREATE_USER',
};

const EditUserPopup = (props) => {
  const {
    row,
    resetList,
    toggleAllRowsExpanded,
    onUpdate,
    onClose,
  } = props;
  const user = _.get(row, ['original']);
  const userImage = _.get(user, ['user_image_paths', 'small']) || '/images/user-avatar3-120x120.png';
  const dispatch = useDispatch();
  const identity = useIdentity();
  const roleOptions = useSelector(getRoleOptions);
  const canUpgradeUser = useSelector(checkCanUpgradeUser);
  const fullFeatureId = useSelector(s => getFeatureIdByName(s, 'FULL'));
  const socialFeatureId = useSelector(s => getFeatureIdByName(s, 'SOCIAL'));
  const dormantFeatureId = useSelector(s => getFeatureIdByName(s, 'DORMANT'));
  const minQuantity = useSelector(selectStripeActiveUsers);
  const quantity = useSelector(selectStripeCalculatedQuantity);
  const companyFeatureId = useSelector(s => getFeatureIdByName(s, identity.features));

  const { isTenant, isTeam, isSupplier, } = getIdentityUtils(identity);

  const [loading, setLoading] = useState(false);
  const [formType, setFormType] = useState(FORM_TYPE.EDIT_USER);
  const [reassignClientUserId, setReassignClientUserId] = useState('');
  const [deactivated, setDeactivated] = useState(false);
  const [reactivated, setReactivated] = useState(false);
  const [errors, setErrors] = useState([]);
  const [state, setState] = useState({
    user_id: '',
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    login_name: '',
    contact_position: '',
    role_id: '',
    production_contact: '0',
    invoicing_contact: '0',
    active: 1,
    ...user
  });
  const [newUserForm, setNewUserForm] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    login_name: '',
    contact_position: '',
    role_id: '',
    production_contact: '0',
    invoicing_contact: '0',
  });

  const isActive = ('' + state.active) === '1';
  const isFullUser = [fullFeatureId, ].includes(('' + state.feature_id));
  const isSocialDormanUser = [socialFeatureId, dormantFeatureId].includes(('' + state.feature_id));
  const showBackBtn = [FORM_TYPE.DEACTIVATE_USER, FORM_TYPE.CREATE_USER].includes(formType);

  useEffect(() => {
    if (!_.get(user, ['user_id'])) { return; }

    oauth('POST', 'client', {
      action: 'FETCH-CLIENTS',
      sales_rep_id: user.user_id,
    }).then(({ json }) => {
      setState(s => ({ ...s, clients_count: json.clients_count }));
    });
  }, [user]);

  useEffect(() => {
    rebuildTooltip();
  }, [user, canUpgradeUser, isActive, isFullUser, isSocialDormanUser]);

  const updateField = useCallback((field, value) => {
    setState(s => ({ ...s, [field]: value }));
  }, []);

  const updateNewUserFormField = useCallback((field, value) => {
    setNewUserForm(s => ({ ...s, [field]: value }));
  }, []);

  const doClose = () => {
    if (formType !== FORM_TYPE.DEACTIVATE_USER
      || (deactivated && formType === FORM_TYPE.DEACTIVATE_USER)
      || (reactivated && formType === FORM_TYPE.EDIT_USER)
    ) {
      onClose && onClose({ row, resetList, toggleAllRowsExpanded, });
    }
  };
  const handleClose = async () => {
    if (formType === FORM_TYPE.CREATE_USER) {
      setErrors([]);
      setLoading(true);
      const data = { ...state };
      if (reassignClientUserId === 'new_user') {
        data['reassign_clients_from'] = user.user_id;
      } else if (reassignClientUserId) {
        data['reassign_clients_from'] = reassignClientUserId;
      }
      return Promise.resolve(
        dispatch(createAddUser(parseAddUserRequestData(identity.company_type, state)))
      ).then(action => {
        if (action.payload.error) {
          if (action.payload.error_type) {
            setErrors(s => s.concat([action.payload.error_type]));
          }
          toast.error(action.payload.error);
          setLoading(false);
        } else {
          dispatch(incrementActiveUsers(1));
          setLoading(false);
        }
      });
    }

    doClose();
  };
  const handleReactivate = () => {
    if (!canUpgradeUser) {
      return;
    }
    setLoading(true);
    setErrors([]);
    const params = {
      active: 1,
    };
    if (isTenant() && !isTeam()) {
      params['feature_id'] = companyFeatureId;
    }
    Promise.resolve(
      dispatch(createUpdateUserData(user.user_id, params))
    ).then(action => {
      setLoading(false);
      if (action.payload.error) {
        toast.error(action.payload.error);
      } else {
        dispatch(incrementActiveUsers(1));
        setReactivated(true);
        doClose();
      }
    });
  };

  const goBack = () => {
    setFormType(s => {
      if (s === FORM_TYPE.CREATE_USER) {
        return FORM_TYPE.DEACTIVATE_USER;
      } else if (s === FORM_TYPE.DEACTIVATE_USER) {
        return FORM_TYPE.EDIT_USER;
      }
      return s;
    });
  };

  const popupTitle = useMemo(() => {
    if (formType === FORM_TYPE.CREATE_USER) {
      return 'Add User';
    } else if (formType === FORM_TYPE.DEACTIVATE_USER) {
      if (deactivated) {
        return 'User Deactivated';
      }
      return 'Deactivate User';
    } else if (reactivated) {
      return 'User Reactivated';
    }
    return 'Edit User';
  }, [formType, deactivated, reactivated]);

  if (!user) { return null; }
  return (
    <Popup
      id={'edit-user-popup'}
      className={(deactivated || reactivated) ? 'edit-user-popup-activated' : 'edit-user-popup-full'}
      closeOnEsc
      style={{
        maxWidth: 580,
        borderRadius: 10,
      }}
      header={<PopupHeader id='edit-user-popup-header'>
        <Col xs={8}>
          {showBackBtn && !reactivated && !deactivated && <IconButton
            style={{ marginRight: 10, }}
            variant='text'
            Icon={ArrowIcon}
            iconProps={{ direction: 'left' }}
            onClick={goBack}
            id='back-btn'
          />}
          {(reactivated || deactivated) && <IconButton
            style={{ marginRight: 10, }}
            variant='text'
            Icon={CheckmarkIcon}
            id='checkmark-btn'
            iconProps={{ color: colors.secondary3.main, }}
          />}
          {!reactivated && !deactivated && <Avatar id='popup-user-avatar' pic={userImage} shape='circle' size='medium' style={{ verticalAlign: 'middle', }} />}
          <Text id='popup-title' style={{ verticalAlign: 'middle', paddingLeft: 10, fontSize: 24, color: colors.neutrals['90'] }}>
            {popupTitle}
          </Text>
        </Col>
        <Col xs={4} end={1}>
          {(formType !== FORM_TYPE.DEACTIVATE_USER || deactivated) && <Button id='done-btn' variant='primary' onClick={handleClose}>
            Done
          </Button>}
        </Col>
      </PopupHeader>}
    >
      <ReactTooltip id={"edit-user-tooltip"} place="right" delayShow={100} />
      <Row id='popup-body'>
        {loading && <Col xs padded style={{ textAlign: 'center' }}>
            <Loading />
        </Col>}
        {(!loading && !reactivated) && <Col xs padded>
          {formType === FORM_TYPE.EDIT_USER && <EditUserForm
            user={state}
            roleOptions={roleOptions}
            updateField={updateField}
            onUpdate={(data) => {
              onUpdate && onUpdate({ ...data, row });
              resetList && resetList();
            }}
          />}
          {formType === FORM_TYPE.EDIT_USER && isActive && <div>
            {!isSocialDormanUser && <Button
              id='deactivate-user-btn'
              variant={'text'}
              onClick={() => setFormType(FORM_TYPE.DEACTIVATE_USER)}
              style={{ color: colors.errors.main, display: 'inline' }}
              size='medium'
            >Deactivate User</Button>}
            {isSocialDormanUser && <Button
              id='reactivate-user-btn'
              variant={canUpgradeUser ? 'text' : 'disabled'}
              onClick={handleReactivate}
              style={{ display: 'inline' }}
              size='medium'
              data-tip={!canUpgradeUser ? "All licenses are in use. Please purchase or transfer a license to add a new user" : ''}
              data-for={!canUpgradeUser ? "edit-user-tooltip" : ''}
            >Reactivate User</Button>}
            <Button
              id='view-user-profile-btn'
              variant={!isActive && !canUpgradeUser ? 'disabled' : 'text'}
              onClick={() => {
                window.open('/user.php?id=' + user.user_id, '_blank');
              }}
              style={{ display: 'inline' }}
              size='medium'
            >View User Profile</Button>
          </div>}
          {(!loading && formType === FORM_TYPE.DEACTIVATE_USER && !deactivated) && <DeactivateUserForm
            user={state}
            isActive={isActive}
            onUpdate={(data) => {
              onUpdate && onUpdate({ ...data, row });
            }}
            showCreateUserForm={() => setFormType(FORM_TYPE.CREATE_USER)}
            reassignClientUserId={reassignClientUserId}
            setReassignClientUserId={setReassignClientUserId}
            setDeactivated={setDeactivated}
          />}
          {formType === FORM_TYPE.CREATE_USER && <CreateUserForm
            user={newUserForm}
            updateField={updateNewUserFormField}
            errors={errors}
          />}
        </Col>}
        {(!loading && deactivated) && <Col padded xs>
          <p id='deactivate-user-success-text'>
            {state.contact_email} has been successfully deactivated.
            <span style={{
              ...(isTeam() || isSupplier() ?  { display: 'none' } : {}),
              paddingLeft: 5,
            }}>You are now using {minQuantity}/{quantity} licenses.</span>
          </p>
        </Col>}

        {(!loading && reactivated) && <Col padded xs>
          <p id='reactivate-user-success-text'>
            {state.contact_email} has been successfully reactivated.
            <span style={{
              ...(isTeam() || isSupplier() ?  { display: 'none' } : {}),
              paddingLeft: 5,
            }}>You are now using {minQuantity}/{quantity} licenses.</span>
          </p>
        </Col>}
      </Row>
    </Popup>
  );
};

export default EditUserPopup;
