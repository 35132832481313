import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AlertNotification, ClipboardIcon, Col, fontFamilies, IconButton, LabeledCheckbox, LabeledIconInput, LabeledInput, LabeledRadio, Link, MailIcon, Row, Text } from '@commonsku/styles';
import { createUpdateUserData } from '../../../actions';
import { formatActivationLink, getIdentityUtils, validateEmailExtensive } from '../../../utils';
import { useIdentity } from '../../../hooks';
import { checkUserIsCollborateChampion } from '../utils';

const EditUserForm = React.memo((props) => {
  const {
    user,
    roleOptions = [],
    updateField,
    onUpdate,
    shouldDispatchUpdate=true,
  } = props;

  const identity = useIdentity();
  const { isSupplier, hasCapabilities } = getIdentityUtils(identity);

  const [errors, setErrors] = useState({ type: '', message: '' });

  const isProductionContact = (user.production_contact + '') === '1';
  const isInvoicingContact = (user.invoicing_contact + '') === '1';
  const dispatch = useDispatch();

  const clearErrors = () => setErrors({ message: '', type: '' });

  const onSaveField = useCallback((field, value, previous_value) => {
    if (!user.user_id || !shouldDispatchUpdate) { return; }
    Promise.resolve(
      dispatch(createUpdateUserData(user.user_id, { [field]: value }))
    ).then(action => {
      if (action.payload.user) {
        onUpdate && onUpdate({ user_id: user.user_id, [field]: value });
      }
    });
  }, [
    user.user_id,
    shouldDispatchUpdate,
    dispatch,
    onUpdate,
  ]);

  const handleUpdateContactEmail = e => {
    if (!user.user_id || !shouldDispatchUpdate) { return; }
    if (!user.contact_email) {
      setErrors({ message: 'Please provide an email', type: 'invalid_email' });
      return;
    }
    if(!validateEmailExtensive(user.contact_email)) {
      setErrors({ message: 'Invalid Email', type: 'invalid_email' });
      return;
    }
    if (!(user.contact_first_name.trim())) {
      setErrors({ message: 'Please provide first name for the user.', type: 'invalid_first_name' });
      return;
    }
    clearErrors();
    const params = { ...user };
    delete params['login_name'];
    dispatch(createUpdateUserData(user.user_id, params));
  };

  const isChampion = checkUserIsCollborateChampion(user);
  if (isSupplier()) {
    return (
      <Row id={'edit-user-form'}>
        <Col xs>
          <LabeledInput
            id={'contact_first_name'}
            label='First name'
            value={user.contact_first_name}
            onChange={e => {
              updateField('contact_first_name', e.target.value);
              clearErrors();
            }}
            onBlur={e => {
              if (!user.user_id || !shouldDispatchUpdate) { return; }
              if (!(user.contact_first_name.trim())) {
                setErrors({ message: 'First name is required', type: 'invalid_first_name' });
                return;
              }
              clearErrors();
              onSaveField('contact_first_name', e.target.value, user.contact_first_name);
            }}
            error={!user.contact_first_name.trim()}
          />
        </Col>
        {errors.type === 'invalid_first_name' ? <Col xs>
          <AlertNotification alertType='error'>
            {errors.message}
          </AlertNotification>
        </Col> : null}
        <Col xs>
          <LabeledInput
            id={'contact_last_name'}
            label='Last name'
            value={user.contact_last_name}
            onChange={e => updateField('contact_last_name', e.target.value)}
            onBlur={e => onSaveField('contact_last_name', e.target.value, user.contact_last_name)}
          />
        </Col>
        <Col xs mt={14} mb={14}>
          <LabeledIconInput
            id={'contact_email'}
            label='Contact email'
            value={user.contact_email}
            Icon={<MailIcon />}
            onChange={e => {
              updateField('contact_email', e.target.value);
              clearErrors();
            }}
            onBlur={handleUpdateContactEmail}
            error={!user.contact_email.trim() || !validateEmailExtensive(user.contact_email)}
          />
        </Col>
        {errors.type === 'invalid_email' ? <Col xs>
          <AlertNotification alertType='error'>
            {errors.message}
          </AlertNotification>
        </Col> : null}
        {hasCapabilities(['FEATURE-COLLABORATE', 'FEATURE-INSIGHTS', 'FEATURE-CONNECTED', 'FEATURE-COMMUNITYBASIC', 'FEATURE-COMMUNITYPLUS', 'FEATURE-CONNECTEDBASIC', 'FEATURE-CONNECTEDPLUS'], true) && <>
          <Col xs>
            <Text as="p">Role</Text>
            {roleOptions.map((v, i) => (
              <LabeledRadio
                key={'role-radio-'+i}
                name={'role_id'}
                label={v.label}
                checked={user.role_id === v.value}
                value={user.role_id === v.value}
                onChange={(e) => {
                  updateField('role_id', v.value);
                  onSaveField('role_id', v.value, user.role_id);
                }}
                labelStyle={{ width: 110 }}
              />
            ))}
          </Col>
          <Col xs>
            <Text as="p">Champion</Text>
            <LabeledRadio
              name={'is_collaborate_champion'}
              label={'Yes'}
              checked={isChampion}
              value={isChampion}
              onChange={(e) => {
                updateField('is_collaborate_champion', 1);
                onSaveField('is_collaborate_champion', 1, user.is_collaborate_champion);
              }}
              labelStyle={{ width: 110 }}
            />
            <LabeledRadio
              name={'is_collaborate_champion'}
              label={'No'}
              checked={!isChampion}
              value={!isChampion}
              onChange={(e) => {
                updateField('is_collaborate_champion', 0);
                onSaveField('is_collaborate_champion', 0, user.is_collaborate_champion);
              }}
              labelStyle={{ width: 110 }}
            />
          </Col>
        </>}
      </Row>
    );
  }

  const activationLink = user.login_setup ? undefined : formatActivationLink(user.user_activation_key);

  return (
    <Row id={'edit-user-form'}>
      <Col xs>
        <LabeledInput
          id={'contact_first_name'}
          label='First name'
          value={user.contact_first_name}
          onChange={e => {
            updateField('contact_first_name', e.target.value);
            clearErrors();
          }}
          onBlur={e => {
            if (!user.user_id || !shouldDispatchUpdate) { return; }
            if (!(user.contact_first_name.trim())) {
              setErrors({ message: 'First name is required', type: 'invalid_first_name' });
              return;
            }
            clearErrors();
            onSaveField('contact_first_name', e.target.value, user.contact_first_name);
          }}
          error={!user.contact_first_name.trim()}
        />
      </Col>
      {errors.type === 'invalid_first_name' ? <Col xs>
        <AlertNotification alertType='error'>
          {errors.message}
        </AlertNotification>
      </Col> : null}
      <Col xs>
        <LabeledInput
          id={'contact_last_name'}
          label='Last name'
          value={user.contact_last_name}
          onChange={e => updateField('contact_last_name', e.target.value)}
          onBlur={e => onSaveField('contact_last_name', e.target.value, user.contact_last_name)}
        />
      </Col>
      <Col xs>
        <LabeledIconInput
          id={'login_name'}
          label='Login email'
          value={user.login_name}
          Icon={<MailIcon />}
          data-place={'top'}
          data-html={true}
          data-tip={`<span style="font-size: 16px;">Please contact <a style="color: #fff; text-decoration: underline;" href="mailto:support@commonsku.com">support@commonsku.com</a> <br/>to change the login email.</span>`}
          data-for="edit-user-tooltip"
          data-effect='solid'
          data-delay-hide='600'
          disabled
        />
      </Col>
      <Col xs mt={14} mb={14}>
        <LabeledIconInput
          id={'contact_email'}
          label='Contact email'
          value={user.contact_email}
          Icon={<MailIcon />}
          onChange={e => {
            updateField('contact_email', e.target.value);
            clearErrors();
          }}
          onBlur={handleUpdateContactEmail}
          error={!user.contact_email.trim() || !validateEmailExtensive(user.contact_email)}
        />
      </Col>
      {errors.type === 'invalid_email' ? <Col xs>
        <AlertNotification alertType='error'>
          {errors.message}
        </AlertNotification>
      </Col> : null}
      <Col xs>
        <LabeledInput
          id={'contact_position'}
          label='Position'
          value={user.contact_position}
          onChange={e => updateField('contact_position', e.target.value)}
          onBlur={e => onSaveField('contact_position', e.target.value, user.contact_position)}
        />
      </Col>
      {!hasCapabilities(['FEATURE-COLLABORATE', 'FEATURE-INSIGHTS', 'FEATURE-CONNECTED', 'FEATURE-COMMUNITYBASIC', 'FEATURE-COMMUNITYPLUS', 'FEATURE-CONNECTEDBASIC', 'FEATURE-CONNECTEDPLUS'], true) && identity.trial_days === null && identity.trial_end_date === null && <>
        <Col xs>
          <Text as="p">Role</Text>
          {roleOptions.map((v, i) => (
            <LabeledRadio
              key={'role-radio-'+i}
              name={'role_id'}
              label={v.label}
              checked={user.role_id === v.value}
              value={user.role_id === v.value}
              onChange={(e) => {
                updateField('role_id', v.value);
                onSaveField('role_id', v.value, user.role_id);
              }}
              labelStyle={{ width: 110 }}
            />
          ))}
        </Col>
      </>}
      <Col xs pt={12}>
        <Text as="p" style={{ marginBottom: 0 }}>Contact</Text>
        <Row>
          <Col xs sm={6}>
            <LabeledCheckbox
              id={'production_contact'}
              label="This is a production contact"
              value={isProductionContact}
              checked={isProductionContact}
              onChange={() => {
                const value = isProductionContact ? 0 : 1;
                updateField('production_contact', value);
                onSaveField('production_contact', value, user.production_contact);
              }}
              labelStyle={{ paddingLeft: 0, paddingTop: 14, }}
              labelProps={{
                'data-html': true,
                'data-place': 'right',
                'data-tip': `<span style="font-size: 16px;">This is your team's <a style="color: #fff; text-decoration: underline;" target="_blank" href="https://help.commonsku.com/knowledge/project-templates#reps">default production rep</a> (should only be assigned to one user)</span>`,
                'data-for': 'edit-user-tooltip',
                'data-delay-hide': '100',
                'data-effect': 'solid',
              }}
            />
          </Col>
          <Col xs sm={6}>
            <LabeledCheckbox
              id={'invoicing_contact'}
              label='This is an invoicing contact'
              value={isInvoicingContact}
              checked={isInvoicingContact}
              onChange={() => {
                const value = isInvoicingContact ? 0 : 1;
                updateField('invoicing_contact', value);
                onSaveField('invoicing_contact', value, user.invoicing_contact);
              }}
              labelStyle={{ paddingLeft: 0, paddingTop: 14, }}
              labelProps={{
                'data-html': true,
                'data-place': 'top',
                'data-tip': `<span style="font-size: 16px;">This is your team's <a style="color: #fff; text-decoration: underline;" target="_blank" href="https://help.commonsku.com/knowledge/project-templates#reps">default invoicing rep</a> (should only be assigned to one user)</span>`,
                'data-for': 'edit-user-tooltip',
                'data-delay-hide': '100',
                'data-effect': 'solid',
              }}
            />
          </Col>
        </Row>
      </Col>
      {activationLink &&
        <Col xs pt={12} mb={15}>
          <Text as="p" style={{ marginBottom: 0 }}>Activation link</Text>
          <Row>
            <Col xs sm={11}>
              <Link as={'a'} href={activationLink} target='_blank' style={{ fontFamily: fontFamilies.regular, fontSize: 16, }}>{activationLink}</Link>
            </Col>
            <Col xs sm={1}>
              <IconButton
                variant='primary-light'
                onClick={e => { navigator.clipboard.writeText(activationLink); }}
                Icon={<ClipboardIcon />}
              />
            </Col>
          </Row>
        </Col>
      }
    </Row>
  );
});

export default EditUserForm;
