import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  colors,
  Popup,
  PopupHeader,
  Row,
  Text,
  UsersIcon,
  Avatar,
  fontFamilies,
  EllipsisIcon,
} from '@commonsku/styles';
import {
  fetchRolePermissions,
  selectPermissionList,
  selectRoleList,
  selectRolePermissions,
  updateRole,
} from '../../redux/permissions';
import PermissionRow from './PermissionRow';
import DefaultMenuSelect from './DefaultMenuSelect';
import ReactTooltip from '../helpers/ReactTooltip';
import { rebuildTooltip } from '../helpers';
import { getUsersList } from '../../selectors/users';
import { useHasCapabilities, useIdentity } from '../../hooks';
import { getIdentityUtils } from '../../utils';

const ManagePermissionsPopup = (props) => {
  const {
    onClose,
  } = props;

  const dispatch = useDispatch();
  const identity = useIdentity();
  const { isSupplier } = getIdentityUtils(identity);
  const permissionsList = useSelector(selectPermissionList);
  const roles = useSelector(selectRoleList);
  const rolePermissions = useSelector(selectRolePermissions);
  const users = useSelector(getUsersList);
  const hasManagementSection = useHasCapabilities('HAS-MANAGEMENT-SECTION');
  const roleUsers = useMemo(() => {
    const result = {};
    users.forEach(v => {
      if (!result[v.role_id]) {
        result[v.role_id] = [];
      }

      result[v.role_id].push({
        user_id: v.user_id,
        contact_full_name: `${v.contact_first_name} ${v.contact_small_name}`.trim(),
        img: _.get(v, ['user_image_paths', 'small']) || '',
      });
    });

    return result;
  }, [users]);
  const permissions = useMemo(() => {
    return permissionsList.filter(
      v => {
        if (isSupplier() && [
          'VIEW-MANAGEMENT-MENU',
          'VIEW-SALES-MENU',
          'VIEW-PRODUCTION-MENU',
          'VIEW-FINANCE-MENU',
          'VIEW-CONNECT-MENU',
        ].includes(v.permission_name)) {
          return false;
        }
        if (v.permission_name === 'PUBLISH-STORE') {
          return false;
        }
        return !((v.permission_name === 'VCRM-MODIFY-ORDER'
          || v.permission_name === 'VCRM-DELETE-ORDER'
          || v.permission_name === 'VCRM-CREATE-ACCOUNT'
          || v.permission_name === 'DELETE-CLIENT'
          || v.permission_name === 'VIEW-REPORT-ALL-USERS'
          || v.permission_name === 'VIEW-REPORT-OWN-TEAMS'
        ) && ['INSIGHTS', 'CONNECTED', 'COMMUNITYBASIC', 'COMMUNITYPLUS', 'CONNECTEDBASIC', 'CONNECTEDPLUS'].includes(identity.features));
      }
    );
  }, [permissionsList, identity, isSupplier]);

  useEffect(() => {
    dispatch(fetchRolePermissions());
    rebuildTooltip();
  }, [dispatch]);

  useEffect(() => {
    rebuildTooltip();
  }, [roleUsers]);

  const handleDone = () => {
    onClose && onClose();
  };

  return (
    <Popup
      id=" manage-permissions-popup"
      onClose={onClose}
      closeOnEsc
      style={{ maxWidth: 1110, borderRadius: 10, }}
      header={<PopupHeader id=" manage-permissions-popup-header">
        <Col xs={6}>
          <Text id=" manage-permissions-popup-title" style={{ verticalAlign: 'middle', paddingLeft: 10, fontSize: 24, color: colors.neutrals['90'] }}>
            Set Permissions
          </Text>
        </Col>
        <Col xs={6} end={1}>
          <Button id=" manage-permissions-popup-done-btn" variant='primary' onClick={handleDone}>
            Done
          </Button>
        </Col>
      </PopupHeader>}
    >
      <ReactTooltip
        place="right"
        id="permission-tooltip"
        delayShow={100}
      />
      {Object.keys(roleUsers).map(r => (
        <ReactTooltip
          key={"role-user-tooltip-"+r}
          id={"role-user-tooltip-"+r}
          place="top"
          effect="solid"
          delayHide={100}
          delayShow={100}
        >
          {roleUsers[r].slice(0, 3).map((v, j) => (
            <Avatar
              key={'role-user-'+j}
              {...(v.img ? { pic: v.img } : { initials: v.contact_full_name.split(' ').map(c => c[0]).join('').toUpperCase() })}
              shape='circle'
              size='medium'
              style={{ verticalAlign: 'middle', marginRight: 8, }}
            />))}
          {roleUsers[r].length > 3 ? <Avatar
            shape='circle'
            size='medium'
            style={{ verticalAlign: 'middle', background: colors.neutrals['90'], }}
          ><EllipsisIcon style={{ verticalAlign: 'middle' }} color={colors.neutrals['80']} /></Avatar> : null}
        </ReactTooltip>
      ))}
      <Row>
        <Col padded xs smOffset={2.7}
          smStyle={{width: '73%'}}
          xsStyle={{width: '100%'}}
          style={{
          paddingBottom: 10,
          textAlign: 'center',
          top: 75,
          background: '#fff',
          zIndex: 9,
          height: 65,
        }}>
          <Row id="roles-header" style={{ paddingBottom: 10 }}>
            {roles.map((r, i) => (
              <Col
                key={`role-header-${i}`}
                xs={1}
                totalCols={roles.length}
                style={{ textAlign: 'center', }}
                data-for={"role-user-tooltip-"+r.role_id}
                data-tip
              >
                <Text style={{ color: '#000000' }}>{r.role_name}</Text>
              </Col>
            ))}
          </Row>
        </Col>
        <Col id="permissions-list" xs padded>
          {permissions.map((v, i) => {
            if (!v.hidden) {
              return <PermissionRow
                key={`role-permission-${i}`}
                permission={v}
                roles={roles}
                rolePermissions={rolePermissions}
              />
            }
          })}
        </Col>
        {!isSupplier() && <>
          <Col padded xs sm={3}>
            <p style={{ color: '#000' }}>Default menu</p>
          </Col>
          <Col padded xs sm={9}>
            <Row style={{ paddingBottom: 10 }}>
              {roles.map((r, i) => (
                <DefaultMenuSelect
                  key={`role-default-menu-${i}`}
                  totalCols={roles.length}
                  rolePermissions={rolePermissions}
                  permissions={permissions}
                  role={r}
                  hasManagementSection={hasManagementSection}
                  onChange={(v) => {
                    dispatch(updateRole(r.role_id, {
                      role_id: r.role_id,
                      default_menu: v.value,
                      _action: 'UPDATE_DEFAULT_MENU',
                    }));
                  }}
                />
              ))}
            </Row>
          </Col>
        </>}
      </Row>
    </Popup>
  );
};

export default ManagePermissionsPopup;
