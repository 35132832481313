import _ from 'lodash';
import React from 'react';
import { colors, Link, Text, } from '@commonsku/styles';
import {
  checkUserLicensePending,
  checkUserProfileSetup,
} from '../../utils';

const UserName = (props) => {
  const { row, style = {}, } = props;
  const isProfileSetup = checkUserProfileSetup(row.original);
  const isLicensePending = checkUserLicensePending(row.original);
  const contactFullName = `${_.get(row.original, ['contact_first_name']) || ''} ${_.get(row.original, ['contact_last_name']) || ''}`.trim();

  const color = isProfileSetup && (''+row.original.active) === '1'
    ? colors.neutrals['90']
    : colors.neutrals['60'];

  return (
    <div className='user-name-col' style={{ ...style, overflowWrap: 'break-word', color, ...(isLicensePending ? { width: 0 } : {}), }}>
      {!isLicensePending && <Text as="p"
        style={{ display: 'block', color: color, fontWeight: 'bold', textDecoration: 'none', marginBottom: 0 }}
      >{contactFullName}</Text>}
    </div>
  );
};

export default UserName;
